import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'
import Table from '../../elements/Table'

import Delete from '../../media/icons/icons8-delete-96.png'

const Main = (props) => {

    const categorys = [
        { value: 'Ancillary', label: 'Ancillary' },
        { value: 'VSC', label: 'VSC' },
    ]

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    console.log(props)

    const [spliteeTableTitles, setSpliteeTableTitles] = useState([
        {
            title: 'Quantity',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Submitted By',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Dealership',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: undefined,
        },
        {
            title: 'Admin',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: undefined,
        },
        {
            title: 'Contract Type',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: undefined,
        },
        {
            title: 'Amount Owed',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${props.data}</span>),
            default: undefined,
        },
        {
            title: 'Orignal Amount',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${props.data}</span>),
            default: undefined,
        },
        {
            title: 'Shipping Address',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: undefined,
        },
        {
            title: 'ATTN',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: undefined,
        },
        {
            title: 'Spiff Type',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: undefined,
        },
    ])

    const [tableValues, setTableValues] = useState([
        [spliteeTableTitles[0].default], 
        [spliteeTableTitles[1].default],
        [spliteeTableTitles[2].default], 
        [spliteeTableTitles[3].default], 
        [spliteeTableTitles[4].default],
        [spliteeTableTitles[5].default], 
        [spliteeTableTitles[6].default], 
        [spliteeTableTitles[7].default],
        [spliteeTableTitles[8].default], 
        [spliteeTableTitles[9].default], 
    ])

    const [tableData, setTableData] = useState([
        [spliteeTableTitles[0].default], 
        [spliteeTableTitles[1].default],
        [spliteeTableTitles[2].default], 
        [spliteeTableTitles[3].default], 
        [spliteeTableTitles[4].default],
        [spliteeTableTitles[5].default], 
        [spliteeTableTitles[6].default], 
        [spliteeTableTitles[7].default],
        [spliteeTableTitles[8].default], 
        [spliteeTableTitles[9].default], 
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
    ])

    const [spliteeRowSelected, setSpliteeRowSelected] = useState([false])

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({})
        };

        props.tokenSafeAPIRequest('/metrics/spiffee/prespiff/' + props?.selectedSpiffee?.id + '?limit=99999', requestOptions, '', (result, status) => {
            console.log(result)
            let tempTableValues = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            let tempTableData = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            result.spiffs.map((spiff) => {
                tempTableValues[0].push(spiff.quantity)
                tempTableValues[1].push(spiff.user_ID?.first_name + ' ' + spiff.user_ID?.last_name)
                tempTableValues[2].push(spiff.dealership_ID?.name)
                tempTableValues[3].push(spiff.admin_ID?.name)
                tempTableValues[4].push(spiff.contract_type + ' - ' + spiff.contract_subtype)
                tempTableValues[5].push(spiff.amount_paid)
                tempTableValues[6].push(spiff.amount)
                tempTableValues[7].push(spiff.address + ', ' + spiff.city + ', ' + spiff.state_code)
                tempTableValues[8].push(spiff.ATTN)
                tempTableValues[9].push(spiff.spiff_type)

                tempTableData[0].push(spiff.quantity)
                tempTableData[1].push(spiff.user_ID?.first_name + ' ' + spiff.user_ID?.last_name)
                tempTableData[2].push(spiff.dealership_ID?.name)
                tempTableData[3].push(spiff.admin_ID?.name)
                tempTableData[4].push(spiff.contract_type + ' - ' + spiff.contract_subtype)
                tempTableData[5].push(spiff.amount)
                tempTableData[6].push(spiff.amount_paid)
                tempTableData[7].push(spiff.address + ', ' + spiff.city + ', ' + spiff.state_code)
                tempTableData[8].push(spiff.ATTN)
                tempTableData[9].push(spiff.spiff_type)
            })

            setTableData(tempTableData)
            setTableValues(tempTableValues)
        })
    },[props?.selectedSpiffee?.id])

    const [deletedTypes, setDeletedTypes] = useState([])

    const addRow = () => {
        console.log('Add Row!')
        setTableValues((spliteeTableValues2) => spliteeTableValues2.map((column, index) => [
            ...column,
            spliteeTableTitles[index].default
        ]))
        setTableData((tableData) => tableData.map((column, index) => [
            ...column,
            spliteeTableTitles[index].default
        ]))
    }

    const deleteRow = (newIndex, currentTableData) => {

        console.log(newIndex, currentTableData)

        console.log(transposeArray(currentTableData)[newIndex])

        setDeletedTypes((deletedFlatees) => [...deletedFlatees, transposeArray(currentTableData)[newIndex]])

        setTableValues((tableValues) => tableValues.map((column) => {
            return [...column.slice(0, newIndex), ...column.slice(newIndex + 1)];
        }))
        setTableData((tableData) => tableData.map((column) => {
            return [...column.slice(0, newIndex), ...column.slice(newIndex + 1)];
        }))
    }

    const onTableChange = (e, y, x) => {
        console.log('Table Updated', e, x, y)
        setTableValues((tableValues) => tableValues.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setTableData((tableData) => tableData.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    const generateKey = () => {
        let tempNum = Math.random() * 100000
        console.log(tempNum)
        return (Math.round(tempNum) + 'F') ;
    }

    return (
        <div style={{width: 'auto', height: '500px', display: 'flex', flexDirection: 'column', maxWidth: '90vw'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>List of Unpaid Prespiff Reports</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <Table colors={props.colors} keys={generateKey()} tableData={tableValues} tableInfo={spliteeTableTitles} tableIDs={tableData} dropdownData={dropdownData} rows={spliteeRowSelected} setRows={setSpliteeRowSelected} onChange={onTableChange} selectMenu={false} key="Modal"></Table>
                <Button colors={props.colors} onClick={() => props.setIsOpen()} active={true} Style={{marginLeft: 'auto', width: '250px', marginTop: '15px'}}>Close</Button>
            </div>
        </div>
    )
}

export default Main
