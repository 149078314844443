import React, { useState, useRef } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable'; // Import the plugin for jsPDF
import IconButton from '../../elements/IconButton';
import Button from '../../elements/Button';

const Main = (props) => {
    const [loadedData, setLoadedData] = useState([]);
    const [stillGettingData, setStillGettingData] = useState(0);
    const isCancelled = useRef(false); // Used to track if fetching was cancelled

    const handleCancelFetch = () => {
        isCancelled.current = true; // Set flag to true when fetching is cancelled
    };

    const fetchAllData = (exportType) => {
        let skip = 0;
        const limit = 15;
        let allData = [];
        setStillGettingData(1);

        const fetchNext = () => {
            if (isCancelled.current) {
                console.log("Fetching has been cancelled by the user.");
                setStillGettingData(0); // Update state to reflect cancellation
                return;
            }
            getData(skip, limit, (hasMoreData, newData) => {
                if (hasMoreData && newData) {
                    allData = allData.concat(newData);
                    skip += limit;
                    fetchNext();
                } else {
                    if (exportType === 'CSV') {
                        downloadCSV(allData, props.exportKeys, props.fileTitle);
                    } else if (exportType === 'PDF') {
                        downloadPDF(allData, props.exportKeys, props.fileTitle);
                    }
                    setStillGettingData(2);
                }
            });
        };

        fetchNext();
    };

    const getData = (skip, limit, onCompletion) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(props.filterData)
        };

        props.tokenSafeAPIRequest(
            `${props.url}${props.specific ? '/' + props.specific : ''}?limit=${limit}&fetch_links=true&skip=${skip}&sort_field=${props.sort}&start_date=${props.calStartDate}&end_date=${props.calEndDate}&active_only=false`,
            requestOptions,
            '',
            (result, status) => {
                if ('postProcessing' in props) {
                    result = props.postProcessing(result);
                }

                if ('reports' in result) {
                    processResult(result.reports, onCompletion);
                } else if ('accounts' in result) {
                    processResult(result.accounts, onCompletion);
                } else if ('transactions' in result) {
                    processResult(result.transactions, onCompletion);
                } else if ('dealerships' in result) {
                    processResult(result.dealerships, onCompletion);
                } else if ('spiffs' in result) {
                    processResult(result.spiffs, onCompletion);
                } else if ('dppps_and_gap' in result) {
                    processResult(result.dppps_and_gap, onCompletion);
                } else {
                    processResult(result, onCompletion);
                }
            }
        );
    };

    const processResult = (data, onCompletion) => {
        if (data && data.length > 0) {
            setLoadedData((loadedData) => [...loadedData, ...data]);
            onCompletion(true, data);
        } else {
            onCompletion(false);
        }
    };

    const downloadCSV = (data, keys, filename = 'data.csv') => {
        const flattenObject = (obj, parentKey = '', res = {}) => {
            for (let key in obj) {
                let propName = parentKey ? parentKey + '.' + key : key;
                if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
                    flattenObject(obj[key], propName, res);
                } else {
                    res[propName] = obj[key];
                }
            }
            return res;
        };

        let csvContent = keys.map(key => key.label).join(',') + '\n';
        data.forEach(item => {
            const flatItem = flattenObject(item);
            const row = keys.map(key => {
                let value = flatItem[key.id] ? flatItem[key.id].toString() : '';
                return `"${value.replace(/"/g, '""')}"`;
            }).join(',');
            csvContent += row + '\n';
        });

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    const downloadPDF = (data, keys, filename = 'data.pdf') => {
        const doc = new jsPDF();

        const addPageNumbers = (doc) => {
            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height;
            const pageWidth = pageSize.width;
    
            // Add footer with page number
            const pageCount = doc.internal.getNumberOfPages();
            for (let i = 1; i <= pageCount; i++) {
                doc.setPage(i);
                doc.text(
                    `Page ${i}`,
                    pageWidth - 30,
                    pageHeight - 10
                );
            }
        };
    
        // Add title
        doc.text(props.fileTitle || 'Report', 20, 10);
    
        // Helper function to flatten nested objects (same as used in CSV)
        const flattenObject = (obj, parentKey = '', res = {}) => {
            for (let key in obj) {
                let propName = parentKey ? parentKey + '.' + key : key;
                if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
                    flattenObject(obj[key], propName, res);
                } else {
                    res[propName] = obj[key];
                }
            }
            return res;
        };
    
        // Flatten the data before adding to the table
        const flattenedData = data.map(item => flattenObject(item));
    
        // Prepare table data for PDF (headers and rows)
        const rows = flattenedData.map(item =>
            keys.map(key => item[key.id] || '')
        );
        const headers = keys.map(key => key.label);
    
        // Add the table using autoTable
        doc.autoTable({
            head: [headers],
            body: rows,
        });

        addPageNumbers(doc);
    
        // Save the PDF
        doc.save(filename);
    };
    

    return (
        <div style={{ width: '360px', height: 'auto', display: 'flex', flexDirection: 'column' }}>
            <div style={{ flexBasis: '10%', display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontWeight: '500', fontSize: '20px', flexBasis: '99%' }}>Export Report</span>
                <IconButton img={false} colors={props.colors} Style={{ borderRadius: '3px', backgroundColor: props.colors.border, width: '24px' }} size="16px">
                    <div onClick={() => { handleCancelFetch(); props.setIsOpen() }} style={{ paddingLeft: '3px', paddingRight: '3px' }}><span onClick={() => { handleCancelFetch(); props.setIsOpen() }}>x</span></div>
                </IconButton>
            </div>
            <div style={{ flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                <span style={{ fontWeight: '500', fontSize: '16px' }}>Downloaded Rows: {loadedData.length}</span>
                <Button colors={props.colors} active={true} Style={{ marginTop: '10px' }} onClick={() => fetchAllData('CSV')}>Download CSV</Button>
                <Button colors={props.colors} active={true} Style={{ marginTop: '10px' }} onClick={() => fetchAllData('PDF')}>Download PDF</Button>
            </div>
        </div>
    );
};

export default Main;
