import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import ManageLayout from '../elements/ManageLayout'
import Sidebar from '../elements/Sidebar'
import ManageHeader from '../elements/ManageHeader'
import Dropdown from '../elements/ReactSelectDropdown'
import TextInput from '../elements/TextInput'
import ManageTable from '../elements/ManageTable'
import IconButton from '../elements/IconButton'
import Button from '../elements/Button'
import ModalManager from '../elements/ModalManager'
import ExportData from '../assets/metrics/ExportData'

import ViewActionsModal from '../assets/manageMGMT/ViewActionsModal'

import Edit from '../media/icons/icons8-edit-96.png'
import Delete from '../media/icons/icons8-delete-96.png'

import CreateNewMGMTModal from '../assets/manageMGMT/CreateNewMGMTModal'
import EditMGMTModal from '../assets/manageMGMT/EditMGMTModal'
import SSNModal from '../assets/manageMGMT/SSNModal'

import Notification from '../elements/Notification'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    const [filterData, setFilterData] = useState([
        {
            key: 'date',
            options: [
                {value: 30, label: '1 Month'},
                {value: 90, label: '3 Months'},
                {value: 180, label: '6 Months'},
                {value: 365, label: '1 Year'},
                {value: 'all', label: 'All'},
            ],
            selectedValue: '',
            selectedDisplay: 'Start Date'
        },
    ])

    const handleFilterDataChange = (index, value) => {
        setFilterData((filterData) => filterData.map((filter, index2) => {
            if (index2 === index)
            {
                filter.selectedValue = value.value;
                filter.selectedDisplay = value.label;
            }
            return filter;
        }))
        refreshAllData()
    }

    const searchElements = [
        (props) => (<div><TextInput colors={props.colors} onChange={(e) => onSearchElementUpdate(e, props.index)} prefix="" width={270} data={searchElementValues[0]}></TextInput></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(0, e)} index={props.index} indexParent={props.indexParent} data={filterData[0].options} width={195} value={filterData[0].selectedDisplay}></Dropdown></div>),
    ]

    const [searchElementValues, setSearchElementValues] = useState([
        '',
    ])

    const formatDateFilters = (date) => {
        console.log(date)
        if (date === '' || date === 'all')
        {
            return '1923-01-01'
        }
        const today = new Date();
        const result = new Date(today);
        result.setDate(today.getDate() - date);
        let day = result.getDate();
        let month = result.getMonth() + 1; // getMonth() returns 0-11
        let year = result.getFullYear().toString(); // Get last two digits
        if (month < 10)
        {
            month = '0' + month
        }
        return `${year}-${month}-${day}`;
    }

    const debounceTimerRef = useRef();

    const onSearchElementUpdate = (e, indexParent) => {
        setSearchElementValues((searchElementValues) => 
            searchElementValues.map((value, index) => 
                indexParent === index ? e : value
        ));

        // Reset the debounce timer
        if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
        }

        debounceTimerRef.current = setTimeout(() => {
            console.log('Searching Subagents')
            searchOfficeStaff(e);
        }, 1000); // 3000 ms = 3 seconds
    }
    
    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Edit MGMT',
            displayElement: (props) => (<IconButton onClick={() => {setEditMGMTModal(true); setSelectedMGMT(props.index)}} Style={{backgroundColor: props.colors.primary}} size={31} image={Edit} colors={props.colors}></IconButton>),
            default: '',
        },
        {
            title: 'Code',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Name',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Active',
            displayElement: (props) => (<div onClick={() => {updateActiveStatus(props.index, props.tableData, props.tableIDs); onTableChange(props.data === 0 ? 1:0, props.index, props.indexParent, props.tableData, props.tableIDs)}} style={{cursor: 'pointer', width: '28px', height: '28px', border: props.data === 1 ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.data === 1  ? props.colors.primary:'', marginTop: '4px', marginRight: '20px', marginLeft: '20px'}}></div>),
            default: undefined,
        },
        {
            title: 'SSN',
            displayElement: (props) => (<Button onClick={() => {setViewSSNModal(true); setSelectedMGMT(props.index)}} colors={props.colors} Style={{borderRadius: '5px'}}>View</Button>),
            default: '',
        },
        {
            title: 'Username',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Actions',
            displayElement: (props) => (<Button onClick={() => {setViewActionsModal(true); setSelectedMGMT(props.index)}} colors={props.colors} Style={{borderRadius: '5px'}}>View</Button>),
            default: true,
        },
        {
            title: 'Start Date',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default], 
        [tableTitles[4].default], 
        [tableTitles[5].default],
        [tableTitles[6].default],
        [tableTitles[7].default],
    ])

    const [tableData, setTableData] = useState([
        [tableTitles[0].default],
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default],
        [tableTitles[4].default],
        [tableTitles[5].default],
        [tableTitles[6].default],
        [tableTitles[7].default],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]], //admin
        [[]], //contract type
        [[]],
        [[]],
        [[]],
    ])

    const [rowSelected, setRowSelected] = useState([false])
    
    const onTableChange = (e, y, x, tableValuesIn, tableDataIn) => {
        console.log('Table Updated', e, x, y)
        setTableValues(() => tableValuesIn.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setTableData(() => tableDataIn.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    function convertDateFormat(dateStr) {
        // Parse the date string
        var dateObj = new Date(dateStr);
    
        // Extracting date components
        var month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // Months are 0-based in JS
        var day = ('0' + dateObj.getDate()).slice(-2);
        var year = dateObj.getFullYear().toString().substr(-2);
    
        // Constructing the new date format
        return month + '/' + day + '/' + year;
    }

    const refreshData = () => {
        //setRefresh((refresh) => !refresh)
        console.log(searchElementValues[0])
        refreshAllData();
    }

    const [MGMT, setMGMT] = useState(false)
    const [selectedMGMT, setSelectedMGMT] = useState(-1)

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const updateActiveStatus = (index, tableData, IDs) => {
        console.log(index)
        console.log(tableData)
        console.log(IDs)

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                resource_state: transposeArray(tableData)[index][3] === 0 ? 1:0,
            })
        };

        console.log(transposeArray(tableData)[index][2] === 0 ? 1:0)
        props.tokenSafeAPIRequest('/user/' + transposeArray(IDs)[index][2], requestOptions, '', (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Updated Status Successfully at: ' + getCurrentTimeFormatted())
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({role: 'management'})
        };

        props.tokenSafeAPIRequest('/user/search?limit=9999999&fetch_links=true&active_only=False' + '&start_date=' + (formatDateFilters(filterData[0].selectedValue) === '' ? '1923-01-01':formatDateFilters(filterData[0].selectedValue)) + '&end_date=2123-01-01', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {

            } else {setNotification(props.getServerResponse(result))}
            setMGMT(result)
            let tempMGMT = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            let tempMGMTData = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];

            if (result.length === 0)
            {

            }
            else
            {
                result.map((MGMT, index) => {
                    tempMGMT[0].push('')
                    tempMGMT[1].push(MGMT.code)
                    tempMGMT[2].push(MGMT.first_name + ' ' + MGMT.last_name)
                    tempMGMT[3].push(MGMT.resource_state)
                    tempMGMT[4].push('')
                    tempMGMT[5].push(MGMT.username)
                    tempMGMT[6].push('')
                    tempMGMT[7].push(convertDateFormat(MGMT.timestamp))
    
                    tempMGMTData[0].push('')
                    tempMGMTData[1].push(MGMT.code)
                    tempMGMTData[2].push(MGMT.id)
                    tempMGMTData[3].push(MGMT.resource_state)
                    tempMGMTData[4].push('')
                    tempMGMTData[5].push(MGMT.username)
                    tempMGMTData[6].push('')
                    tempMGMTData[7].push(MGMT.timestamp)
                })
    
                setTableValues(tempMGMT)
                setTableData(tempMGMTData)
            }
        })
    },[])

    const refreshAllData = () => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({role: 'management'})
        };

        props.tokenSafeAPIRequest('/user/search?limit=10000&fetch_links=true&active_only=false' + '&start_date=' + (formatDateFilters(filterData[0].selectedValue) === '' ? '1923-01-01':formatDateFilters(filterData[0].selectedValue)) + '&end_date=2123-01-01', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {

            } else {setNotification(props.getServerResponse(result))}
            setMGMT(result)
            let tempMGMT = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            let tempMGMTData = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            if (result.length === 0)
            {
                setTableValues([
                    [tableTitles[0].default],
                    [tableTitles[1].default],
                    [tableTitles[2].default],
                    [tableTitles[3].default],
                    [tableTitles[4].default],
                    [tableTitles[5].default],
                    [tableTitles[6].default],
                    [tableTitles[7].default],
                ])
                setTableData([
                    [tableTitles[0].default],
                    [tableTitles[1].default],
                    [tableTitles[2].default],
                    [tableTitles[3].default],
                    [tableTitles[4].default],
                    [tableTitles[5].default],
                    [tableTitles[6].default],
                    [tableTitles[7].default],
                ])
            }
            else
            {
                result.map((MGMT, index) => {
                    tempMGMT[0].push('')
                    tempMGMT[1].push(MGMT.code)
                    tempMGMT[2].push(MGMT.first_name + ' ' + MGMT.last_name)
                    tempMGMT[3].push(MGMT.resource_state)
                    tempMGMT[4].push('')
                    tempMGMT[5].push(MGMT.username)
                    tempMGMT[6].push('')
                    tempMGMT[7].push(convertDateFormat(MGMT.timestamp))
    
                    tempMGMTData[0].push('')
                    tempMGMTData[1].push(MGMT.code)
                    tempMGMTData[2].push(MGMT.id)
                    tempMGMTData[3].push(MGMT.resource_state)
                    tempMGMTData[4].push('')
                    tempMGMTData[5].push(MGMT.username)
                    tempMGMTData[6].push('')
                    tempMGMTData[7].push(MGMT.timestamp)
                })

                console.log('Search Element: ' + searchElementValues[0])
    
                if (searchElementValues[0] === '')
                {
                    console.log('No Search Element')
                    setTableValues(tempMGMT)
                    setTableData(tempMGMTData)
                }
                else
                {
                    console.log('Found Search Value')
                    searchOfficeStaff(searchElementValues[0])
                }
            }
        })
    }

    const searchOfficeStaff = (searchValue) => {

        console.log(searchValue)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        console.log('Searching By: ' + searchValue)
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({name: searchValue, role: 'management'})
        };

        props.tokenSafeAPIRequest('/user/search?limit=10000&fetch_links=true&active_only=false' + '&start_date=' + (formatDateFilters(filterData[0].selectedValue) === '' ? '1923-01-01':formatDateFilters(filterData[0].selectedValue)) + '&end_date=2123-01-01', requestOptions, tokenValues, (result, status) => {
            if (status === 200)
            {

            } else {setNotification(props.getServerResponse(result))}
            console.log(result)
            setMGMT(result)
            let tempMGMT = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            let tempMGMTData = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];

            if (result.length === 0)
            {
                setTableValues([
                    [tableTitles[0].default],
                    [tableTitles[1].default],
                    [tableTitles[2].default],
                    [tableTitles[3].default],
                    [tableTitles[4].default],
                    [tableTitles[5].default],
                    [tableTitles[6].default],
                    [tableTitles[7].default],
                ])
                setTableData([
                    [tableTitles[0].default],
                    [tableTitles[1].default],
                    [tableTitles[2].default],
                    [tableTitles[3].default],
                    [tableTitles[4].default],
                    [tableTitles[5].default],
                    [tableTitles[6].default],
                    [tableTitles[7].default],
                ])
            }
            else
            {
                result.map((MGMT, index) => {
                    tempMGMT[0].push('')
                    tempMGMT[1].push(MGMT.code)
                    tempMGMT[2].push(MGMT.first_name + ' ' + MGMT.last_name)
                    tempMGMT[3].push(MGMT.resource_state)
                    tempMGMT[4].push('')
                    tempMGMT[5].push(MGMT.username)
                    tempMGMT[6].push('')
                    tempMGMT[7].push(convertDateFormat(MGMT.timestamp))
    
                    tempMGMTData[0].push('')
                    tempMGMTData[1].push(MGMT.code)
                    tempMGMTData[2].push(MGMT.id)
                    tempMGMTData[3].push(MGMT.resource_state)
                    tempMGMTData[4].push('')
                    tempMGMTData[5].push(MGMT.username)
                    tempMGMTData[6].push('')
                    tempMGMTData[7].push(MGMT.timestamp)
                })
    
                setTableValues(tempMGMT)
                setTableData(tempMGMTData)
            }
        })
    }

    function setEmptyStringsToNull(obj) {
        Object.keys(obj).forEach(key => {
            if (obj[key] === '') {
                obj[key] = null;
            }
        });
        return obj;
    }

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const [notification, setNotification] = useState('')
    const [editMGMTModal, setEditMGMTModal] = useState(false)
    const [createNewMGMTModal, setCreateNewMGMTModal] = useState(false)
    const [viewActionsModal, setViewActionsModal] = useState(false)
    const [viewSSNModal, setViewSSNModal] = useState(false)

    const addNewMGMT = (newMGMT) => {
        console.log(newMGMT)

        newMGMT = {...newMGMT, role: 'management', subagent: false}

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(newMGMT)
        };

        props.tokenSafeAPIRequest('/user', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setCreateNewMGMTModal(false)
                setNotification('Created New MGMT Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const editMGMT = (newMGMTInfo) => {
        console.log(newMGMTInfo)

        newMGMTInfo = setEmptyStringsToNull(newMGMTInfo);

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(newMGMTInfo)
        };

        props.tokenSafeAPIRequest('/user/' + newMGMTInfo.id, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setEditMGMTModal(false)
                setNotification('Edited Management Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }
    
    const deleteMGMT = (id) => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/user/' + id, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setEditMGMTModal(false)
                setNotification('Deleted Management Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const generateKey = () => {
        let tempNum = Math.random() * 100000
        console.log(tempNum)
        return (Math.round(tempNum) + 'F') ;
    }

    const [exportMGMT, setExportMGMT] = useState(false)

    const exportKeys = [{id: 'code', label: 'MGMT Code'}, {id: 'name', label: 'Name'}, {id: 'resource_state', label: 'Active'}, {id: 'SSN', label: 'SSN'}, {id: 'username', label: 'Username'}, {id: 'timestamp', label: 'Start Date'}]

    return (
        <ManageLayout colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <ManageHeader colors={props.colors} searchElements={searchElements} buttonText={'Add New MGMT'} buttonText2={'Export MGMT'} onClick2={() => setExportMGMT(true)} title='Manage MGMT' onClick={() => setCreateNewMGMTModal(true)} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></ManageHeader>
            <ManageTable colors={props.colors} tableData={tableValues} tableInfo={tableTitles} keys={generateKey()} tableIDs={tableData} dropdownData={dropdownData} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false} onBottom={() => ''}></ManageTable>
            <ModalManager colors={props.colors}>
                <CreateNewMGMTModal colors={props.colors} isOpen={createNewMGMTModal} setIsOpen={() => setCreateNewMGMTModal(false)} addNewMGMT={addNewMGMT}></CreateNewMGMTModal>
                <EditMGMTModal colors={props.colors} isOpen={editMGMTModal} setIsOpen={() => setEditMGMTModal(false)} MGMT={MGMT} selectedMGMT={selectedMGMT} editMGMT={editMGMT} deleteMGMT={deleteMGMT}></EditMGMTModal>
                <ViewActionsModal colors={props.colors} isOpen={viewActionsModal} setIsOpen={() => setViewActionsModal(false)} MGMT={MGMT} selectedMGMT={selectedMGMT} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></ViewActionsModal>
                <SSNModal colors={props.colors} isOpen={viewSSNModal} setIsOpen={() => setViewSSNModal(false)} MGMT={MGMT} selectedMGMT={selectedMGMT} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></SSNModal>
                <ExportData colors={props.colors} isOpen={exportMGMT} setIsOpen={() => setExportMGMT(false)} url={'/user/search'} filterData={{role: 'management'}} tokenSafeAPIRequest={props.tokenSafeAPIRequest} exportKeys={exportKeys} fileTitle={'List of all MGMT Export'} calStartDate={'1900-01-01'} calEndDate={'2100-01-01'}></ExportData>
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </ManageLayout>
    )
}

export default Main
