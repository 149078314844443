import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import OutputLayoutColumn from '../elements/OutputLayoutColumn'

import Sidebar from '../elements/Sidebar'
import Controller from '../elements/Controller'
import OutputColumn from '../elements/OutputColumn'
import Button from '../elements/Button'
import OutputHeader from '../elements/OutputHeader'
import OutputFilter from '../elements/OutputFilter'
import OutputBarChart from '../elements/OutputBarChart'
import OutputTable from '../elements/OutputTable'

import Dropdown from '../elements/ReactSelectDropdown'
import ModalManager from '../elements/ModalManager'
import FiltersModal from '../assets/metrics/FiltersModal'

import ExportData from '../assets/metrics/ExportData'
import InsideSalesReportExportData from '../assets/metrics/InsideSalesReportExportData'
import CalendarModal from '../assets/metrics/CalendarModal'

import ViewSaturdayReport from '../assets/metrics/ViewSaturdayReport'

import { useCookies } from 'react-cookie'

import WeekSelector from '../elements/WeekSelector'

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])
    const [filterData, setFilterData] = useState([
        {
            title: 'Admin',
            data: '',
            value: {label: '', value: ''},
            key: 'admin_ID',
        },
        {
            title: 'Report Type',
            data: [
                {value: 'Saturday', label: 'Saturday'},
                {value: 'Adjustment', label: 'Adjustment'},
                {value: 'Weekly 7 Reversal', label: 'Weekly 7 Reversal'},
                {value: 'Void', label: 'Void'},
                {value: 'Rejection', label: 'Rejection'},
                {value: 'Unwind', label: 'Unwind'},
                {value: 'Cancellation', label: 'Cancellation'},
                {value: 'Over / Under', label: 'Over / Under'},
                {value: 'Payback', label: 'Payback'},
                {value: 'Claim', label: 'Claim'},
                {value: '100% Cancellation', label: '100% Cancellation'},
            ],
            value: {label: '', value: ''},
            key: 'report_type',
        },
        {
            title: 'Contract Type',
            data: '',
            value: {label: '', value: ''},
            key: 'contract_type',
        },
    ])

    const formatDateFilters = (date) => {
        if (!(date instanceof Date)) {
            return '';
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString(); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${year}-${month}-${day}`;
    }

    const [calStartDate, setCalStartDate] = useState(('2000-01-01'))
    const [calEndDate, setCalEndDate] = useState(('2200-01-01'))

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Quantity',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Report Type',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Submitted By',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Subagent',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Dealer',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Admin',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'IS Net/c',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${props.data}</span>),
            default: 0,
        },
        {
            title: 'IS Net Total',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${props.data}</span>),
            default: 0,
        },
        {
            title: 'Reason',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: 0,
        },
        {
            title: 'View Full Report',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}><Button onClick={() => viewFullReport(props.tableIndex, props.index, props.tableData)} Style={{borderRadius: '5px'}} colors={props.colors}>View</Button></span>),
            default: '',
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default], 
        [tableTitles[4].default],
        [tableTitles[5].default],
        [tableTitles[6].default],
        [tableTitles[7].default],
        [tableTitles[8].default],
        [tableTitles[9].default],
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e,x,y) => {
        console.log(e,x,y)
    }

    const [allDealers, setAllDealers] = useState([])
    const [allAdmins, setAllAdmins] = useState([])
    const [selectedDealer, setSelectedDealer] = useState({value: '', label: 'All Dealers'})
    const [headerValues, setHeaderValues] = useState([])
    const [skip, setSkip] = useState(0)
    const [filtersModal, setFiltersModal] = useState(false)
    const [sort, setSort] = useState('timestamp')

    function formatDate(date) {
        if (!(date instanceof Date)) {
            return '';
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString().substr(-2); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${month}/${day}/${year}`;
    }



    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({})
        }

        let tempDealerList = []
        let tempAdminList = []
        let tempContractList = []

        //get dealerships
        props.tokenSafeAPIRequest('/dealership/names?active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)
            tempDealerList.push({value: '', label: 'All Dealers'})
            result.map((dealer, index) => {
                tempDealerList.push({value: dealer.id, label: dealer.name})
            })
            setAllDealers(tempDealerList)
        })

        //get admins
        props.tokenSafeAPIRequest('/admin/search?limit=99999&active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)
            result.map((admin, index) => {
                tempAdminList.push({value: admin.id, label: admin.name})
            })
        })

        requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
          }

        //get all contract types
        props.tokenSafeAPIRequest('/admin/contract_types?active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)
            result.VSC_contracts.map((type, index) => {
                tempContractList.push({value: 'VSC - ' + type, label: 'VSC - ' + type})
            })
            result.ancillary_contracts.map((type, index) => {
                tempContractList.push({value: 'Ancillary - ' + type, label: 'Ancillary - ' + type})
            })
        })

        setFilterData([
            {
                title: 'Admin',
                data: tempAdminList,
                value: {label: '', value: ''},
                key: 'admin_ID',
            },
            {
                title: 'Report Type',
                data: [
                    {value: 'Saturday', label: 'Saturday'},
                    {value: 'Adjustment', label: 'Adjustment'},
                    {value: 'Weekly 7 Reversal', label: 'Weekly 7 Reversal'},
                    {value: 'Void', label: 'Void'},
                    {value: 'Rejection', label: 'Rejection'},
                    {value: 'Unwind', label: 'Unwind'},
                    {value: 'Cancellation', label: 'Cancellation'},
                    {value: 'Over / Under', label: 'Over / Under'},
                    {value: 'Payback', label: 'Payback'},
                    {value: 'Claim', label: 'Claim'},
                    {value: '100% Cancellation', label: '100% Cancellation'},
                ],
                value: {label: '', value: ''},
                key: 'report_type',
            },
            {
                title: 'Contract Type',
                data: tempContractList,
                value: {label: '', value: ''},
                key: 'contract_type',
            },
        ])
    },[])
    
    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        console.log('WE GOT HERE 1')

        let filterDataBy = {}
        let startDate = ''
        filterData.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result      
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })

        console.log('WE GOT HERE')
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            dealership_ID: selectedDealer.value === '' ? null:selectedDealer.value,
            ...filterDataBy
          })
        }

        props.tokenSafeAPIRequest('/metrics/inside_sales?fetch_links=true&limit=15&start_date=' + calStartDate + '&end_date=' + calEndDate + '&sort_field=' + sort, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (result.reports.length === 0)
            {
                setTableValues([
                    [], 
                    [],
                    [],
                    [], 
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                ])
                let tempStats = [
                    {title: 'Total Dealerships', value: 0},
                    {title: 'Total IS Net', value: '$' + 0},
                    {title: 'Total Reports', value: 0},
                ]
                setHeaderValues(tempStats)
            }
            else
            {
                let tempStats = [
                    {title: 'Total Dealerships', value: formatNumber(result.total_dealerships)},
                    {title: 'Total IS Net', value: '$' + formatNumber(result.total_net)},
                    {title: 'Total Reports', value: (formatNumber(result.total_reports))},
                ]
                setHeaderValues(tempStats)

                let tempTableValues = [
                    [], 
                    [],
                    [],
                    [], 
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                ]

                let tempReportID = []
                result.reports.map((report, index) => {
                    tempTableValues[0].push(report.quantity)
                    tempTableValues[1].push(report.report_subtype === 'Adjustment' ? (report.report_type + (report.contract_subtype === null ? '':' - ' + report.contract_subtype)):(report.report_subtype === "Reserve Release" ? 'Reserve Release':report.contract_type + ' - ' + report.contract_subtype))
                    tempTableValues[2].push(report.submitted_by.first_name + " " + report.submitted_by.last_name)
                    tempTableValues[3].push(report?.subagent_ID?.first_name + " " + report?.subagent_ID?.last_name)
                    tempTableValues[4].push(report.dealership_ID === null ? 'No Dealership':report?.dealership_ID?.name)
                    tempTableValues[5].push(report.admin_ID === null ? 'No Admin':report?.admin_ID?.name)
                    tempTableValues[6].push(report.inside_sales_payout)
                    tempTableValues[7].push(report.total_is_net)
                    tempTableValues[8].push(report.reason)
                    tempTableValues[9].push(report.code)
                    tempReportID.push([report.id, report.code])
                })
                
                setTableValues(tempTableValues)
            }
        })
        setSkip(0)
    },[selectedDealer, filterData, sort, calStartDate, calEndDate])

    const onBottom = () => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let filterDataBy = {}
        let startDate = ''
        filterData.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result
                
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            dealership_ID: selectedDealer.value === '' ? null:selectedDealer.value,
            ...filterDataBy
          })
        }

        props.tokenSafeAPIRequest('/metrics/inside_sales?fetch_links=true&limit=15&skip=' + (skip+15) + '&start_date=' + calStartDate + '&end_date=' + calEndDate + '&sort_field=' + sort, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (result.reports.length === 0)
            {
                return '';
            }
            else
            {

                let tempTableValues = tableValues

                let tempReportID = []
                result.reports.map((report, index) => {
                    tempTableValues[0].push(report.quantity)
                    tempTableValues[1].push(report.report_subtype === null ? report.report_type:report.report_subtype)
                    tempTableValues[2].push(report.submitted_by.first_name + " " + report.submitted_by.last_name)
                    tempTableValues[3].push(report?.subagent_ID?.first_name + " " + report?.subagent_ID?.last_name)
                    tempTableValues[4].push(report.dealership_ID === null ? 'No Dealership':report?.dealership_ID?.name)
                    tempTableValues[5].push(report.admin_ID === null ? 'No Admin':report?.admin_ID?.name)
                    tempTableValues[6].push(report.inside_sales_payout)
                    tempTableValues[7].push(report.total_is_net)
                    tempTableValues[8].push(report.reason)
                    tempTableValues[9].push(report.code)
                    tempReportID.push([report.id, report.code])
                })

                console.log(tempTableValues)
                
                setTableValues(tempTableValues)
            }
        })
        setSkip((skip) => skip + 15)
    }

    const updateSelectedDealer = (dealer) => {
        console.log(dealer)
        setSelectedDealer(dealer)
    }

    const saveFilters = (data, displayData) => {
        console.log('Saving Filters')
        setFilterData((prevState, index) => {
            return prevState.map((item, index) => {
                return {...item, value: {value: data[item.key], label: displayData[item.key]}}
            })
        })
    } 

    const removeFilter = (key) => {
        setFilterData((prevState, index) => {
            return prevState.map((item, index) => {
                if (item.key === key)
                {
                    return {...item, value: {value: '', label: ''}}
                }
                else {return item}
            })
        })

        setFilterModalDisplayData((prevState) => {
            return {...prevState, [key]: ''}
        })

        setFilterModalData((prevState) => {
            return {...prevState, [key]: ''}
        })
    }

    const [filterModalDisplayData, setFilterModalDisplayData] = useState({
        admin_ID: '',
        report_type: '',
        contract_type: '',
        date: '',
    })

    const [filterModalData, setFilterModalData] = useState({
        admin_ID: '',
        report_type: '',
        contract_type: '',
        date: '',
    })

    const updateFilterModalData = (e, key) => {
        setFilterModalDisplayData(data => ({
            ...data,
            [key]: e.label
        }))
        setFilterModalData(data => ({
            ...data,
            [key]: e.value
        }))
    }

    const sortOptions = [
        { value: 'inside_sales_payout', label: 'IS Net' },
        { value: 'net', label: '30% of Net' },
        { value: 'timestamp', label: 'Date' },
        { value: 'quantity', label: 'Quantity' },
    ]

    const sortToName = {
        timestamp: 'Date',
        net: '30% of Net',
        inside_sales_payout: 'IS Net',
        quantity: 'Quantity'
    }

    const masterList = [
        {
            name: 'Export Report',
            link: '',
            onClick: () => {setExportDataModal(true)},
        },
        {
            name: 'Combined Dealer Report',
            link: '/output/combinedDealerReport',
            key: "CombinedDealerReport",
            onClick: () => {},
        },
        {
            name: 'Combined Subagent Report',
            link: '/output/combinedSubagentReport',
            key: "CombinedSubagentReport",
            onClick: () => {},
        },
        {
            name: 'Contract Count Report',
            link: '/output/contractCountReport',
            key: "ContractCountReport",
            onClick: () => {},
        },
        {
            name: 'Inside Sales Report',
            link: '/output/insidesalesreport',
            key: "InsideSalesReport",
            onClick: () => {},
        },
        // {
        //     name: '-List of Gift Cards',
        //     link: '/output/listOfGiftCards',
        //     key: "GiftCardList",
        //     onClick: () => {},
        // },
        {
            name: 'List of Reserve Input/Outputs',
            link: '/output/listOfReserveInputs',
            key: "ReserveAccounts",
            onClick: () => {},
        },
        // {
        //     name: '-List of Reserve Outputs',
        //     link: '/output/listOfReserveOutputs',
        //     key: "ReserveInputs",
        //     onClick: () => {},
        // },
        {
            name: 'List of Saturday Reports',
            link: '/output/listOfSaturdayReports',
            key: "SaturdayReport",
            onClick: () => {},
        },
        {
            name: 'New Dealer Signups',
            link: '/output/newDealerSignups',
            key: "NewDealerSignups",
            onClick: () => {},
        },
        // {
        //     name: '-Outstanding Pre-spiffs',
        //     link: '/output/outstandingPrespiffs',
        //     key: "OutstandingPrespiffs",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Recent Address Changes',
        //     link: '/output/recentAddressChanges',
        //     key: "RecentAddressChanges",
        //     onClick: () => {},
        // },
        {
            name: 'Reporting History',
            link: '/output/reportingHistory',
            key: "ReportingHistory",
            onClick: () => {},
        },
        // {
        //     name: '-Reserve Account Reports',
        //     link: '/output/reserveAccountReports',
        //     key: "ReserveAccountReport",
        //     onClick: () => {},
        // },
        {
            name: 'Spiffees Report',
            link: '/output/spiffeesReport',
            key: "SpiffeesReport",
            onClick: () => {},
        },
        // {
        //     name: '-Subagent Business',
        //     link: '/output/subagentBusiness',
        //     key: "SubagentBusiness",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Subagent Net & Inside Sales',
        //     link: '/output/subagentNetAndInsideSales',
        //     key: "SubagentNetInsideSales",
        //     onClick: () => {},
        // },
        {
            name: 'Void & Needs to be Replaced',
            link: '/output/voidAndNeedsReplaced',
            key: "VoidAndNeedsToBeReplaced",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Weeks List',
            link: '/output/reserveAccountWeeks',
            key: "ReserveAccountWeeks",
            onClick: () => {},
        },
        {
            name: 'Combined Spiffees Report',
            link: '/output/combinedSpiffeesReport',
            key: "CombinedSpiffeesReport",
            onClick: () => {},
        },
        {
            name: 'List of Expense Reports',
            link: '/output/expenseReports',
            key: "ListOfExpenseReports",
            onClick: () => {},
        },
        {
            name: 'List of Adjustment Reports',
            link: '/output/adjustmentReports',
            key: "ListOfAdjustmentReports",
            onClick: () => {},
        },
        {
            name: 'List of DPPP/GAP Plus',
            link: '/output/DPPPGAP',
            key: "ListOfDPPP/GAP",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Totals',
            link: '/output/reserveAccountTotals',
            key: "ReserveAccountTotals",
            onClick: () => {},
        },
        {
            name: 'Prespiff Paid Off',
            link: '/output/prespiffPaidOff',
            key: "PrespiffPaidOff",
            onClick: () => {},
        },
        {
            name: 'Dealer Totals',
            link: '/output/dealerTotals',
            key: "DealerTotals",
            onClick: () => {},
        },
    ]

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/permission/me', requestOptions, '', (result, status) => {
            console.log(result) //'*':'*' - Superadmin
            let tempButtonList = [{
                name: 'Export Report',
                link: '',
                onClick: () => {setExportDataModal(true)},
            }]
            if (result.static_role === 'superadmin' || result.static_role === 'admin')
            {
                localStorage.setItem('button_list', JSON.stringify(masterList))
                setButtonList(masterList)
            }
            else
            {
                result.allow.Metrics.map((metric) => {
                    masterList.map((button) => {
                        if (metric === button.key)
                        {
                            tempButtonList.push(button)
                        }
                    })
                })
                tempButtonList = tempButtonList.sort((a,b) => {
                    if (a.name === "Export Report") return -1; // Move "Export Report" to the top
                    if (b.name === "Export Report") return 1;
                    return a.name.localeCompare(b.name);
                })
                localStorage.setItem('button_list', JSON.stringify(tempButtonList))
                setButtonList(tempButtonList)
            }
        })  
    },[])

    const [exportDataModal, setExportDataModal] = useState(false)
    const [buttonList, setButtonList] = useState(JSON.parse(localStorage.getItem('button_list')))

    const exportKeys = [{id: 'quantity', label: 'QTY'}, {id: 'report_subtype', label: 'Report Type'}, {id: 'submitted_by.first_name', label: 'Subagent/OS'}, {id: 'submitted_by.last_name', label: 'Subagent/OS'}, {id: 'dealership_ID.name', label: 'Dealership'}, {id: 'admin_ID.name', label: 'Admin'}, {id: 'inside_sales_payout', label: 'IS Net'}, {id: 'reason', label: 'Reason'}]

    const buildFilterData = (data) => {
        let filterDataBy = {}
        let startDate = ''
        data.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
        return {
            dealership_ID: selectedDealer.value === '' ? null:selectedDealer.value,
            start_date: startDate,
            ...filterDataBy
        }
    }

    const [selectDatesModal, setSelectDatesModal] = useState(false)

    const filterDate = (start, end) => {
        if (start.getTime() > end.getTime())
        {
            
        }
        else
        {
            start = formatDateFilters(start)
            end = formatDateFilters(end)
            setCalStartDate(start)
            setCalEndDate(end)
        }
    }

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const viewFullReport = (table, index, tableData) => {
        let reportCode = transposeArray(tableData)[index][9]

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        props.tokenSafeAPIRequest('/report/full/' + reportCode, requestOptions, '', (result) => {
            console.log(result)
            setSelectedReport(result)
            setViewSatReport(true)
        })
    }

    const [viewSatReport, setViewSatReport] = useState(false)
    const [selectedReport, setSelectedReport] = useState('')

    return (
        <OutputLayoutColumn colors={props.colors}>
            <Sidebar colors={props.colors} tab={1}></Sidebar>
            <Controller colors={props.colors} tokenSafeAPIRequest={props.tokenSafeAPIRequest} activeButton={true} buttonList={buttonList} title={"Outputs"}></Controller>
            <OutputColumn colors={props.colors} title={"Dealers"}></OutputColumn>
            <div> {/*THERE IS A HIDDEN STYLE TAG FROM THE OUTPUT LAYOUT --- width: "auto", marginLeft: '20px', display: 'flex', flexDirection: 'column'*/}
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Dropdown colors={props.colors} onChange={(e) => updateSelectedDealer(e)} index={props.index} indexParent={props.indexParent} data={allDealers} width={195} value={selectedDealer.label}>Test</Dropdown>
                    <span style={{fontWeight: '600', marginLeft: '20px', marginTop: '6px'}}>Filter Subagent</span>
                    <WeekSelector colors={props.colors} onClick={() => setSelectDatesModal(true)} active={true} calStartDate={calStartDate} calEndDate={calEndDate} Style={{marginLeft: 'auto', marginRight: '20px'}}>{calStartDate === '2000-01-01' ? '':calStartDate + ' | '} Select Week | {calEndDate === '2200-01-01' ? 'All Times':calEndDate}</WeekSelector>
                </div>
                <OutputHeader colors={props.colors} headerValues={headerValues}></OutputHeader>
                <OutputFilter colors={props.colors} onClick={() => setFiltersModal(true)} filterData={filterData} removeFilter={removeFilter} setSort={setSort} sort={sort} sortOptions={sortOptions} sortToName={sortToName}></OutputFilter>
                <OutputBarChart colors={props.colors} title={'Net'}></OutputBarChart>
                <OutputTable colors={props.colors} tableData={tableValues} tableInfo={tableTitles} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false} onBottom={onBottom}></OutputTable>
            </div>
            <ModalManager colors={props.colors}>
                <FiltersModal colors={props.colors} isOpen={filtersModal} setIsOpen={() => setFiltersModal(false)} filterData={filterData} saveFilters={saveFilters} displayData={filterModalDisplayData} data={filterModalData} updateData={updateFilterModalData}></FiltersModal>
                {/* <ExportData colors={props.colors} isOpen={exportDataModal} calStartDate={calStartDate} calEndDate={calEndDate} setIsOpen={() => setExportDataModal(false)} filterData={buildFilterData(filterData)} url={'/metrics/inside_sales'} sort={sort} tokenSafeAPIRequest={props.tokenSafeAPIRequest} exportKeys={exportKeys} fileTitle={'Inside Sales Report'}></ExportData> */}
                <InsideSalesReportExportData colors={props.colors} isOpen={exportDataModal} calStartDate={calStartDate} calEndDate={calEndDate} setIsOpen={() => setExportDataModal(false)} filterData={buildFilterData(filterData)} url={'/metrics/inside_sales/export'} sort={sort} tokenSafeAPIRequest={props.tokenSafeAPIRequest} exportKeys={exportKeys} fileTitle={'Inside Sales Report'}></InsideSalesReportExportData>
                <CalendarModal colors={props.colors} isOpen={selectDatesModal} calStartDate={calStartDate} calEndDate={calEndDate} setIsOpen={() => setSelectDatesModal(false)} filterDate={filterDate}></CalendarModal>
                <ViewSaturdayReport colors={props.colors} isOpen={viewSatReport} setIsOpen={() => setViewSatReport(false)} selectedReport={selectedReport}></ViewSaturdayReport>
            </ModalManager>
        </OutputLayoutColumn>
    )
}

export default Main
