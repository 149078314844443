import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'
import Table from '../../elements/Table'

import GoTo from '../../media/icons/icons8-share-96.png'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'ID',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Name',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Role',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Address',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Contact',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Admins',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'IS',
            displayElement: (props) => (<div style={{width: '28px', height: '28px', border: props.data === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.data === true  ? props.colors.primary:''}}></div>),
            default: '',
        },
        {
            title: 'Incentive Form',
            displayElement: (props) => (<div style={{width: '28px', height: '28px', border: props.data === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.data === true  ? props.colors.primary:''}}></div>),
            default: '',
        },
        {
            title: 'W7',
            displayElement: (props) => (<div style={{width: '28px', height: '28px', border: props.data === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.data === true  ? props.colors.primary:''}}></div>),
            default: '',
        },
        {
            title: 'Goto',
            displayElement: (props) => (<Link to="/manage/dealers"><IconButton image={GoTo} colors={props.colors} size="24px" Style={{padding: '3px', backgroundColor: props.colors.primary, borderRadius: '5px'}}></IconButton></Link>),
            default: undefined,
        },
    ])

    const [tableValues, setTableValues] = useState([
        [], 
        [],
        [], 
        [], 
        [], 
        [], 
        [], 
        [], 
        [], 
        [], 
    ])

    const [tableData, setTableData] = useState([
        [], 
        [],
        [], 
        [], 
        [], 
        [], 
        [], 
        [], 
        [], 
        [], 
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]], //admin
        [[]], //contract type
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e,x,y) => {
        console.log(e,x,y)
    }

    const resolveConnectionType = (dealer, id) => {
        let result = ''
        dealer.user_connections.map((user) => {
            if (user.user_ID.id === id)
            {
                if (user.owner)
                {
                    result = 'Owner'
                }
                else
                {
                    result = user.connection_type
                }
            }
        })
        return result;
    }

    const extractAdmins = (admins) => {
        let tempAdminList = ''
        admins.map((admin) => {
            tempAdminList = tempAdminList + admin.code + ', '
        })
        tempAdminList = tempAdminList.slice(0, -2) //cuts off last 2 characters which is a ,
        return tempAdminList
    }

    useEffect(() => {

        console.log(props.subagents[props.selectedSubagent])

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/user/full/' + props.subagents[props.selectedSubagent].id + '?fetch_links=true', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            let tempTableData = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            let tempTableValues = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            result.dealership_connections.map((dealer, index) => {
                if (dealer.code === null) {console.log(index)}
                tempTableData[0].push(dealer.code)
                tempTableData[1].push(dealer.name)
                tempTableData[2].push(resolveConnectionType(dealer, props.subagents[props.selectedSubagent].id))
                tempTableData[3].push(
                    [
                        dealer.address,
                        dealer.city,
                        dealer.state_code
                    ].filter(value => value !== null) // Filter out null values
                    .join(',')
                );
                tempTableData[4].push(dealer.phone_number)
                tempTableData[5].push(extractAdmins(dealer.admin_IDs))
                tempTableData[6].push(dealer.inside_sales)
                tempTableData[7].push(dealer.signed_incentive_form)
                tempTableData[8].push(dealer.weekly_7)
                tempTableData[9].push('')

                tempTableValues[0].push(dealer.code)
                tempTableValues[1].push(dealer.name)
                tempTableValues[2].push(resolveConnectionType(dealer, props.subagents[props.selectedSubagent].id))
                tempTableValues[3].push(
                    [
                        dealer.address,
                        dealer.city,
                        dealer.state_code
                    ].filter(value => value !== null) // Filter out null values
                    .join(',')
                );
                tempTableValues[4].push(dealer.phone_number)
                tempTableValues[5].push(extractAdmins(dealer.admin_IDs))
                tempTableValues[6].push(dealer.inside_sales)
                tempTableValues[7].push(dealer.signed_incentive_form)
                tempTableValues[8].push(dealer.weekly_7)
                tempTableValues[9].push('')
            })

            tempTableData.map((data, index) => {
                if (index === 3)
                {
                    console.log(data)
                    return data.toString().replace(/null/g, '')
                }
                else {return data}
            })

            setTableData(tempTableData)
            setTableValues(tempTableValues)
        })
    },[])

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const downloadCSV = (tableValues) => {
        // Column titles
        const headers = ['Code', 'Dealership', 'Role', 'Address', 'Contact', 'Admins', 'IS', 'Incentive Form', 'W7'];
    
        // Function to format and escape the data
        const formatData = (data) => {
            return '"' + String(data).replace(/"/g, '""') + '"'; // Enclose in quotes and escape quotes
        };
    
        // Convert array of arrays to CSV
        const csvContent = [
            headers.join(','), // add header row
            ...tableValues.map(row => row.slice(0, -1).map(formatData).join(',')) // format each row and slice off the last element
        ].join('\n');
    
        // Create a blob from the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
    
        // Create a link and trigger download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'export.csv'); // Name the file here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    
    const generateKey = () => {
        let tempNum = Math.random() * 100000
        console.log(tempNum)
        return (Math.round(tempNum) + 'F') ;
    }

    return (
        <div style={{width: 'auto', height: 'auto', display: 'flex', flexDirection: 'column', maxWidth: '1700px'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: 'auto'}}>Subagent Dealers Modal</span>
                <Button onClick={() => downloadCSV(transposeArray(tableValues))} active={true} colors={props.colors} Style={{marginRight: 'auto', marginLeft: '20px', marginTop: '-5px'}}>Download CSV</Button>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <Table colors={props.colors} keys={generateKey()} tableData={tableValues} tableInfo={tableTitles} dropdownData={dropdownData} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false} onBottom={() => ''} key='dealerModal'></Table>
            </div>
        </div>
    )
}

export default Main
