import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import ManageLayout from '../elements/ManageLayout'
import Sidebar from '../elements/Sidebar'
import ManageHeader from '../elements/ManageHeader'
import Dropdown from '../elements/ReactSelectDropdown'
import TextInput from '../elements/TextInput'
import ManageTable from '../elements/ManageTable'
import IconButton from '../elements/IconButton'
import Button from '../elements/Button'
import ModalManager from '../elements/ModalManager'

import EditAdminModal from '../assets/manageAdmins/EditAdminModal'
import EditContractTypesModal from '../assets/manageAdmins/EditContractTypesModal'
import CreateNewAdminModal from '../assets/manageAdmins/CreateNewAdminModal'

import Edit from '../media/icons/icons8-edit-96.png'
import Delete from '../media/icons/icons8-delete-96.png'

import Notification from '../elements/Notification'

import { useCookies } from 'react-cookie'

import ViewReports from '../assets/managePrespiffs/ViewReports'

const Main = (props) => {

    const [cookies, setCookies]= useCookies([])

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const refreshData = () => {
        //setRefresh((refresh) => !refresh)
        //refreshAllData();
    }

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let tempSpiffeesList = []
        let tempDealerList = []

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({})
        }

        props.tokenSafeAPIRequest('/spiffee/names?active_only=false', requestOptions, '', (result) => {
            console.log(result)
            tempSpiffeesList.push({value: '', label: 'All Spiffees'})
            result.map((spiffee, index) => {
                tempSpiffeesList.push({value: spiffee.id, label: spiffee.name})
            })
        })

        props.tokenSafeAPIRequest('/dealership/names?active_only=false', requestOptions, '', (result) => {
            console.log(result)
            tempDealerList.push({value: '', label: 'All Dealerships'})
            result.map((dealer, index) => {
                tempDealerList.push({value: dealer.id, label: dealer.name})
            })
        })

        setFilterData([
            {
                key: 'spiffee_ID',
                options: tempSpiffeesList,
                selectedValue: '',
                selectedDisplay: 'Spiffee'
            },
            {
                key: 'spiffee_ID',
                options: tempDealerList,
                selectedValue: '',
                selectedDisplay: 'Dealerships'
            },
            {
                key: 'sort_field',
                options: [
                    {value: 'spiffee_balance', label: 'Balance'},
                    {value: 'timestamp', label: 'Date'},
                ],
                selectedValue: 'timestamp',
                selectedDisplay: 'Sort By'
            },
        ])
    },[])

    const [filterData, setFilterData] = useState([])
    const [filterDataChange, setFilterDataChange] = useState(false)

    const handleFilterDataChange = (index, value) => {
        console.log('filterChange!')
        setSkip(0)
        setFilterData((filterData) => filterData.map((filter, index2) => {
            if (index2 === index)
            {
                filter.selectedValue = value.value;
                filter.selectedDisplay = value.label;
            }
            return filter;
        }))
        setFilterDataChange((filterDataChange) => !filterDataChange)
    }
    
    const searchElements = [
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(0, e)} index={props.index} indexParent={props.indexParent} data={filterData[0]?.options} width={195} value={filterData[0]?.selectedDisplay}></Dropdown></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(1, e)} index={props.index} indexParent={props.indexParent} data={filterData[1]?.options} width={195} value={filterData[1]?.selectedDisplay}></Dropdown></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(2, e)} index={props.index} indexParent={props.indexParent} data={filterData[2]?.options} width={195} value={filterData[2]?.selectedDisplay}></Dropdown></div>),
    ]

    const [searchElementValues, setSearchElementValues] = useState([
        '',
    ])

    const debounceTimerRef = useRef();

    const onSearchElementUpdate = (e, indexParent) => {
        setSearchElementValues((searchElementValues) => 
            searchElementValues.map((value, index) => 
                indexParent === index ? e : value
        ));

        // Reset the debounce timer
        if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
        }

        debounceTimerRef.current = setTimeout(() => {
            console.log('Searching Subagents')
            setSearchUpdate((searchUpdate) => !searchUpdate)
            //searchAdmins(e);
        }, 1000); // 3000 ms = 3 seconds
    }

    const [viewReports, setViewReports] = useState(false)
    const [selectedSpiffee, setSelectedSpiffee] = useState(0)
    const [spiffees, setSpiffees] = useState([])
    const [searchUpdate, setSearchUpdate] = useState(false)
    const [pagination, setPagination] = useState(false)
    const [skip, setSkip] = useState(0)
    
    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Code',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Name',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Spiffee Balance',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${formatNumber(props.data)}</span>),
            default: false,
        },
        {
            title: 'Prespiff Reports',
            displayElement: (props) => (<Button onClick={() => {setSelectedSpiffee(props.index); setViewReports(true);}} colors={props.colors} Style={{borderRadius: '5px'}}>Types</Button>),
            default: '',
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default], 
    ])

    const [tableData, setTableData] = useState([
        [tableTitles[0].default],
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]],
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e, x, y, tableValuesIn, tableDataIn) => {
        console.log('Table Updated', e, x, y)
        setTableValues(() => tableValuesIn.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setTableData(() => tableDataIn.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    const [notification, setNotification] = useState('')

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    let runOnce = false;

    useEffect(() => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let formattedFilters = {}
        filterData.map((filter) => {
            if (filter.selectedValue === '')
            {

            }
            else
            {
                if (filter.key === 'contract_type')
                {
                    console.log(filter.selectedValue)
                    if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                    {
                        formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                    else
                    {
                        formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                }
                formattedFilters[filter.key] = filter.selectedValue
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...formattedFilters,
            search_term: searchElementValues[0]
        })
        };

        props.tokenSafeAPIRequest('/metrics/spiffee/prespiff?limit=15&skip=' + skip + '&fetch_links=true&sort_field=' + (filterData[2]?.selectedValue === undefined ? 'timestamp':filterData[2]?.selectedValue), requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {

            } else {setNotification(props.getServerResponse(result))}
            let tempPrespiffs = null
            let tempPrespiffsData = null
            if (skip > 0)
            {
                tempPrespiffsData = tableData; 
                tempPrespiffs = tableValues;
                setSpiffees(prevState => ({
                    ...prevState,
                    spiffees: [...prevState.spiffees, ...result.spiffees] // Append new spiffees
                }))
            }
            else
            {
                setSpiffees(result)
                tempPrespiffs = [
                    [],
                    [],
                    [],
                    [],
                ];
                tempPrespiffsData = [
                    [],
                    [],
                    [],
                    [],
                ];
            }

            result.spiffees.map((prespiff, index) => {
                tempPrespiffs[0].push(prespiff.code)
                tempPrespiffs[1].push(prespiff.name)
                tempPrespiffs[2].push(prespiff.spiffee_balance)
                tempPrespiffs[3].push('')

                tempPrespiffsData[0].push(prespiff.code)
                tempPrespiffsData[1].push(prespiff.name)
                tempPrespiffsData[2].push(prespiff.spiffee_balance)
                tempPrespiffsData[3].push('')
            })

            setTableData(tempPrespiffsData)
            setTableValues(tempPrespiffs)
            setSkip((skip) => (skip+15))
        })
    },[searchUpdate, pagination, filterDataChange])

    return (
        <ManageLayout colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <ManageHeader colors={props.colors} searchElements={searchElements} onClick={''} buttonText={''} title='Manage Prespiffs' tokenSafeAPIRequest={props.tokenSafeAPIRequest}></ManageHeader>
            <ManageTable colors={props.colors} tableData={tableValues} tableIDs={tableData} tableInfo={tableTitles} rows={rowSelected} dropdownData={dropdownData} setRows={setRowSelected} onChange={onTableChange} onBottom={() => setPagination((pagination) => !pagination)} selectMenu={false}></ManageTable>
            <ModalManager colors={props.colors}>
                <ViewReports colors={props.colors} isOpen={viewReports} setIsOpen={() => setViewReports(false)} selectedSpiffee={spiffees?.spiffees?.[selectedSpiffee]} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></ViewReports>
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </ManageLayout>
    )
}

export default Main
