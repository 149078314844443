import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'
import Table from '../../elements/Table'

import Delete from '../../media/icons/icons8-delete-96.png'
import GoTo from '../../media/icons/icons8-share-96.png'

import LinkImg from '../../media/icons/icons8-share-96.png'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    console.log(props)

    const generateKey = () => {
        let tempNum = Math.random() * 100000
        console.log(tempNum)
        return (Math.round(tempNum) + 'F') ;
    }

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const checkIfNotBlacklisted = (adminCode) => {
        if (props.dealerWeekly7[props.selectedDealer].weekly7 === false)
        {
            return false;
        }
        let isGood = true;
        props.dealerWeekly7[props.selectedDealer].blacklist.forEach((admin) => {
            if (admin.id === adminCode)
            {
                isGood = false;
            }
        })
        return isGood;
    }

    useEffect(() => {
        let admins = props.dealerAdmins[props.selectedDealer]

        let tempAdminInfos = [
            [],
            [],
            [],
        ]
        let tempAdminData = [
            [],
            [],
            [],
        ]

        admins.map((admin, index) => {
            console.log(admin)
            tempAdminInfos[0].push(admin.code)
            tempAdminInfos[1].push(admin.name)
            tempAdminInfos[2].push(checkIfNotBlacklisted(admin.id))

            tempAdminData[0].push(admin.code)
            tempAdminData[1].push(admin.id)
            tempAdminData[2].push(checkIfNotBlacklisted(admin.id))
        })

        setAdminTableValues(tempAdminInfos)
        setAdminTableData(tempAdminData)
    },[])


    //ADMIN CODE

    const [deletedAdmins, setDeletedAdmins] = useState([])

    const [adminTableTitles, setAdminsTableTitles] = useState([
        {
            title: 'Code',
            displayElement: (props) => (<span style={{width: '50px', marginLeft: '30px', marginRight: '30px'}}>{props.data}</span>),
            default: '-',
        },
        {
            title: 'Name',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Allow',
            displayElement: (props) => (<div onClick={() => onAdminTableChange(!props.data, props.index, props.indexParent)} style={{cursor: 'pointer', width: '28px', height: '28px', border: props.data === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.data === true  ? props.colors.primary:''}}></div>),
            default: undefined,
        },
    ])

    const [adminTableValues, setAdminTableValues] = useState([
        [adminTableTitles[0].default], 
        [adminTableTitles[1].default],
        [adminTableTitles[2].default], 
    ])

    const [adminTableData, setAdminTableData] = useState([
        [adminTableTitles[0].default],
        [adminTableTitles[1].default],
        [adminTableTitles[2].default],
    ])

    const [adminDropdownData, setAdminDropdownData] = useState([
        [[]],
        [[]],
        [[]],
    ])

    const onAdminTableChange = (e, y, x) => {
        console.log('Table Updated', e, x, y)
        setAdminTableValues((tableValues) => tableValues.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setAdminTableData((tableData) => tableData.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    const [toggle, setToggle] = useState(false)

    const toggleSelection = () => {
        adminTableValues[2].map((admin, index) => {
            onAdminTableChange(toggle, index, 2)
        })
        setToggle(() => !toggle)
    }

    //END ADMIN CODE

    return (
        <div style={{width: 'auto', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', flexDirection: 'column', height: '500px'}}>
                <Table colors={props.colors} keys={generateKey()} tableData={adminTableValues} tableIDs={adminTableData} tableInfo={adminTableTitles} dropdownData={adminDropdownData} onChange={onAdminTableChange} selectMenu={false} key='Modal'></Table>
                <div style={{marginTop: '15px', display: 'flex', flexDirection: 'row'}}>
                    <Button onClick={() => toggleSelection()} colors={props.colors} active={true} Style={{marginRight: 'auto', width: '200px'}}>Toggle All</Button>
                    <Button onClick={() => props.updateWeekly7(adminTableValues, adminTableData)} colors={props.colors} active={true} Style={{marginLeft: 'auto', width: '150px'}}>Save</Button>
                </div>
            </div>
        </div>
    )
}

export default Main
