import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import ManageLayout from '../elements/ManageLayout'
import Sidebar from '../elements/Sidebar'
import ManageHeader from '../elements/ManageHeader'
import Dropdown from '../elements/ReactSelectDropdown'
import TextInput from '../elements/TextInput'
import ManageTable from '../elements/ManageTable'
import IconButton from '../elements/IconButton'
import Button from '../elements/Button'
import ModalManager from '../elements/ModalManager'
import ExportData from '../assets/metrics/ExportData'

import EditSpiffeeModal from '../assets/manageSpiffees/EditSpiffeeModal'
import EditSubagentsModal from '../assets/manageSpiffees/EditSubagentsModal'
import EditDealersModal from '../assets/manageSpiffees/EditDealersModal'
import CreateNewSpiffeeModal from '../assets/manageSpiffees/CreateNewSpiffeeModal'
import EditBalanceModal from '../assets/manageSpiffees/EditBalanceModal'

import Edit from '../media/icons/icons8-edit-96.png'
import Delete from '../media/icons/icons8-delete-96.png'

import Notification from '../elements/Notification'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [refresh, setRefresh] = useState(false)

    const refreshData = (skip) => {       
        if (skip !== undefined)
        {
            console.log('hmmm')
            setRefresh((refresh) => !refresh)
        }
        else
        {
            console.log('hmmm2')
            refreshAllData();
        }
    }

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    const [cookies, setCookies] = useCookies([])
    const [skip, setSkip] = useState(15)

    const [selectedSpiffee, setSelectedSpiffee]= useState()

    const [spiffees, setSpiffees] = useState([])

    const debounceTimerRef = useRef();

    const [checkIfRan, setCheckIfRan] = useState(false)

    useEffect(() => {
        //THIS IS NOT THE NORMAL USEEFFECT
        //this is a special useEffect specifically for refreshing data from a weekly7/active change as instead of passing the data through the table and back into this element, I can just use this instead.
        //this mirrors refreshAllData(), I cant call that method as its being called from inside the table so the skip value is not the current skip value and will always return 0 (the initally set value).
        if (checkIfRan)
        {
            let tokenValues = {
                refresh_token: cookies['refresh_token'],
                refresh_token_expires: cookies['refresh_token_expires'],
                access_token: cookies['access_token'],
                access_token_expires: cookies['access_token_expires'],
            }
    
            //get dealers
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
            myHeaders.append("Content-Type", "application/json");
        
            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              redirect: 'follow',
              body: JSON.stringify({})
            };
    
            let limit = 0;
            if (skip === 0)
            {
                limit = 30
            }
            else
            {
                limit = skip;
            }
    
            props.tokenSafeAPIRequest('/spiffee/search?limit=' + limit + '&fetch_links=true&reverse_sort=false&active_only=false', requestOptions, tokenValues, (result, status) => {
                if (status === 200)
                {
    
                } else {setNotification(props.getServerResponse(result))}
                let tempSpiffees = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                ];
                let tempSpiffeesData = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                ];
                console.log(result)
                setSpiffees(result);
                result.map((spiffee, index) => {
                    tempSpiffees[0].push('')
                    tempSpiffees[1].push(spiffee.code)
                    tempSpiffees[2].push(spiffee.name)
                    tempSpiffees[3].push(spiffee.position)
                    tempSpiffees[4].push(spiffee.dealership_IDs)
                    tempSpiffees[5].push((spiffee.city === null ? '':spiffee.city) + ', ' + (spiffee.state_code === null ? '':spiffee.state_code))
                    tempSpiffees[6].push(spiffee.resource_state)
                    tempSpiffees[7].push(spiffee.subagent_IDs)
                    tempSpiffees[8].push(spiffee.phone_number)
                    tempSpiffees[9].push(spiffee.email)
                    tempSpiffees[10].push(spiffee.birthday)
    
                    tempSpiffeesData[0].push('')
                    tempSpiffeesData[1].push(spiffee.code)
                    tempSpiffeesData[2].push(spiffee.id)
                    tempSpiffeesData[3].push(spiffee.position)
                    tempSpiffeesData[4].push(spiffee.dealership_IDs)
                    tempSpiffeesData[5].push((spiffee.city === null ? '':spiffee.city) + ', ' + (spiffee.state_code === null ? '':spiffee.state_code))
                    tempSpiffeesData[6].push(spiffee.resource_state)
                    tempSpiffeesData[7].push(spiffee.subagent_IDs)
                    tempSpiffeesData[8].push(spiffee.phone_number)
                    tempSpiffeesData[9].push(spiffee.email)
                    tempSpiffeesData[10].push(spiffee.birthday)
                })
    
                if (searchElementValues[0] === '')
                {
                    console.log('No search detected')
                    setTableValues(tempSpiffees)
                    setTableData(tempSpiffeesData)
                }
                else
                {
                    console.log('Search Detected!')
                    searchSpiffees(searchElementValues[0], limit)
                }
            })
    
            // setSkip((skip) => skip + 15)
    
        }
    }, [refresh])


    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        let formattedFilters = {}
        filterData.map((filter) => {
            if (filter.selectedValue === '')
            {

            }
            else
            {
                if (filter.key === 'contract_type')
                {
                    console.log(filter.selectedValue)
                    if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                    {
                        formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                    else
                    {
                        formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                }
                formattedFilters[filter.key] = filter.selectedValue
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...formattedFilters,
        })
        }

        props.tokenSafeAPIRequest('/spiffee/search?limit=30&fetch_links=true&reverse_sort=false&active_only=false', requestOptions, tokenValues, (result, status) => {
            if (status === 200)
            {

            } else {setNotification(props.getServerResponse(result))}
            let tempSpiffees = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            let tempSpiffeesData = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            console.log(result)
            setSpiffees(result);
            result.map((spiffee, index) => {
                tempSpiffees[0].push('')
                tempSpiffees[1].push(spiffee.code)
                tempSpiffees[2].push(spiffee.name)
                tempSpiffees[3].push(spiffee.position)
                tempSpiffees[4].push(spiffee.dealership_IDs)
                tempSpiffees[5].push((spiffee.city === null ? '':spiffee.city) + ', ' + (spiffee.state_code === null ? '':spiffee.state_code))
                tempSpiffees[6].push(spiffee.resource_state)
                tempSpiffees[7].push(spiffee.subagent_IDs)
                tempSpiffees[8].push(spiffee.phone_number)
                tempSpiffees[9].push(spiffee.email)
                tempSpiffees[10].push(spiffee.birthday)
                tempSpiffees[11].push('')

                tempSpiffeesData[0].push('')
                tempSpiffeesData[1].push(spiffee.code)
                tempSpiffeesData[2].push(spiffee.id)
                tempSpiffeesData[3].push(spiffee.position)
                tempSpiffeesData[4].push(spiffee.dealership_IDs)
                tempSpiffeesData[5].push((spiffee.city === null ? '':spiffee.city) + ', ' + (spiffee.state_code === null ? '':spiffee.state_code))
                tempSpiffeesData[6].push(spiffee.resource_state)
                tempSpiffeesData[7].push(spiffee.subagent_IDs)
                tempSpiffeesData[8].push(spiffee.phone_number)
                tempSpiffeesData[9].push(spiffee.email)
                tempSpiffeesData[10].push(spiffee.birthday)
                tempSpiffeesData[11].push('')
            })

            console.log('qqqqqqqqqqqqqqqqqqqq' + searchElementValues[0])

            if (searchElementValues[0] === '')
            {
                console.log('No Need to search!')
                setTableValues(tempSpiffees)
                setTableData(tempSpiffeesData)
            }
            else
            {
                console.log('Need to search!')
                searchSpiffees(searchElementValues[0], skip)
            }
        })

        setSkip((skip) => skip + 15)
        setCheckIfRan(true)
    }, [])

    const onBottom = () => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let formattedFilters = {}
        filterData.map((filter) => {
            if (filter.selectedValue === '')
            {

            }
            else
            {
                if (filter.key === 'contract_type')
                {
                    console.log(filter.selectedValue)
                    if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                    {
                        formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                    else
                    {
                        formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                }
                formattedFilters[filter.key] = filter.selectedValue
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...formattedFilters,
            name: searchElementValues[0]
        })
        }
    
        props.tokenSafeAPIRequest('/spiffee/search?limit=15&fetch_links=true&reverse_sort=false&active_only=false&skip=' + skip, requestOptions, tokenValues, (result) => {

            console.log(result)

            let tempSpiffees = tableValues;
            let tempSpiffeesData = tableData

            setSpiffees((spiffees) => spiffees.concat(result));

            result.map((spiffee, index) => {
                tempSpiffees[0].push('')
                tempSpiffees[1].push(spiffee.code)
                tempSpiffees[2].push(spiffee.name)
                tempSpiffees[3].push(spiffee.position)
                tempSpiffees[4].push(spiffee.dealership_IDs)
                tempSpiffees[5].push((spiffee.city === null ? '':spiffee.city) + ', ' + (spiffee.state_code === null ? '':spiffee.state_code))
                tempSpiffees[6].push(spiffee.resource_state)
                tempSpiffees[7].push(spiffee.subagent_IDs)
                tempSpiffees[8].push(spiffee.phone_number)
                tempSpiffees[9].push(spiffee.email)
                tempSpiffees[10].push(spiffee.birthday)
                tempSpiffees[11].push('')

                tempSpiffeesData[0].push('')
                tempSpiffeesData[1].push(spiffee.code)
                tempSpiffeesData[2].push(spiffee.id)
                tempSpiffeesData[3].push(spiffee.position)
                tempSpiffeesData[4].push(spiffee.dealership_IDs)
                tempSpiffeesData[5].push((spiffee.city === null ? '':spiffee.city) + ', ' + (spiffee.state_code === null ? '':spiffee.state_code))
                tempSpiffeesData[6].push(spiffee.resource_state)
                tempSpiffeesData[7].push(spiffee.subagent_IDs)
                tempSpiffeesData[8].push(spiffee.phone_number)
                tempSpiffeesData[9].push(spiffee.email)
                tempSpiffeesData[10].push(spiffee.birthday)
                tempSpiffeesData[11].push('')
            })

            setTableValues(tempSpiffees)
            setTableData(tempSpiffeesData)
        })

        setSkip((skip) => skip + 15)
    }

    //makes custom request to take all current data and update it.
    const refreshAllData = (currentSkip, searchValue) => {
        console.log('Refreshing Data')
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        let formattedFilters = {}
        filterData.map((filter) => {
            if (filter.selectedValue === '')
            {

            }
            else
            {
                if (filter.key === 'contract_type')
                {
                    console.log(filter.selectedValue)
                    if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                    {
                        formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                    else
                    {
                        formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                }
                formattedFilters[filter.key] = filter.selectedValue
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...formattedFilters,
            })
        }

        let tempSkip = currentSkip === undefined ? skip:currentSkip

        console.log(skip)

        props.tokenSafeAPIRequest('/spiffee/search?limit=' + tempSkip + '&fetch_links=true&reverse_sort=false&active_only=false', requestOptions, tokenValues, (result, status) => {
            if (status === 200)
            {

            } else {setNotification(props.getServerResponse(result))}
            let tempSpiffees = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            let tempSpiffeesData = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            console.log(result)
            setSpiffees(result);
            result.map((spiffee, index) => {
                tempSpiffees[0].push('')
                tempSpiffees[1].push(spiffee.code)
                tempSpiffees[2].push(spiffee.name)
                tempSpiffees[3].push(spiffee.position)
                tempSpiffees[4].push(spiffee.dealership_IDs)
                tempSpiffees[5].push((spiffee.city === null ? '':spiffee.city) + ', ' + (spiffee.state_code === null ? '':spiffee.state_code))
                tempSpiffees[6].push(spiffee.resource_state)
                tempSpiffees[7].push(spiffee.subagent_IDs)
                tempSpiffees[8].push(spiffee.phone_number)
                tempSpiffees[9].push(spiffee.email)
                tempSpiffees[10].push(spiffee.birthday)
                tempSpiffees[11].push('')

                tempSpiffeesData[0].push('')
                tempSpiffeesData[1].push(spiffee.code)
                tempSpiffeesData[2].push(spiffee.id)
                tempSpiffeesData[3].push(spiffee.position)
                tempSpiffeesData[4].push(spiffee.dealership_IDs)
                tempSpiffeesData[5].push((spiffee.city === null ? '':spiffee.city) + ', ' + (spiffee.state_code === null ? '':spiffee.state_code))
                tempSpiffeesData[6].push(spiffee.resource_state)
                tempSpiffeesData[7].push(spiffee.subagent_IDs)
                tempSpiffeesData[8].push(spiffee.phone_number)
                tempSpiffeesData[9].push(spiffee.email)
                tempSpiffeesData[10].push(spiffee.birthday)
                tempSpiffeesData[11].push('')
            })

            console.log('Search Element: ' + searchElementValues[0])

            if (searchElementValues[0] === '')
            {
                console.log('No search detected')
                setTableValues(tempSpiffees)
                setTableData(tempSpiffeesData)
            }
            else
            {
                console.log('Search Detected!')
                searchSpiffees(searchElementValues[0], tempSkip)
            }
        })

        setSkip((skip) => skip + 15)

    }

    const state_list = [
        {value: '', label: 'All States'},
        {value: 'AL', label: 'AL'},
        {value: 'AK', label: 'AK'},
        {value: 'AZ', label: 'AZ'},
        {value: 'AR', label: 'AR'},
        {value: 'CA', label: 'CA'},
        {value: 'CO', label: 'CO'},
        {value: 'CT', label: 'CT'},
        {value: 'DE', label: 'DE'},
        {value: 'FL', label: 'FL'},
        {value: 'GA', label: 'GA'},
        {value: 'HI', label: 'HI'},
        {value: 'ID', label: 'ID'},
        {value: 'IL', label: 'IL'},
        {value: 'IN', label: 'IN'},
        {value: 'IA', label: 'IA'},
        {value: 'KS', label: 'KS'},
        {value: 'KY', label: 'KY'},
        {value: 'LA', label: 'LA'},
        {value: 'ME', label: 'ME'},
        {value: 'MD', label: 'MD'},
        {value: 'MA', label: 'MA'},
        {value: 'MI', label: 'MI'},
        {value: 'MN', label: 'MN'},
        {value: 'MS', label: 'MS'},
        {value: 'MO', label: 'MO'},
        {value: 'MT', label: 'MT'},
        {value: 'NE', label: 'NE'},
        {value: 'NV', label: 'NV'},
        {value: 'NH', label: 'NH'},
        {value: 'NJ', label: 'NJ'},
        {value: 'NM', label: 'NM'},
        {value: 'NY', label: 'NY'},
        {value: 'NC', label: 'NC'},
        {value: 'ND', label: 'ND'},
        {value: 'OH', label: 'OH'},
        {value: 'OK', label: 'OK'},
        {value: 'OR', label: 'OR'},
        {value: 'PA', label: 'PA'},
        {value: 'RI', label: 'RI'},
        {value: 'SC', label: 'SC'},
        {value: 'SD', label: 'SD'},
        {value: 'TN', label: 'TN'},
        {value: 'TX', label: 'TX'},
        {value: 'UT', label: 'UT'},
        {value: 'VT', label: 'VT'},
        {value: 'VA', label: 'VA'},
        {value: 'WA', label: 'WA'},
        {value: 'WV', label: 'WV'},
        {value: 'WI', label: 'WI'},
        {value: 'WY', label: 'WY'},
    ];

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let tempContractList = []
        let tempDealerList = []
        let tempSubagentList = []

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({})
          }

        props.tokenSafeAPIRequest('/dealership/names?active_only=false', requestOptions, '', (result) => {
            console.log(result)
            tempDealerList.push({value: '', label: 'All Dealers'})
            result.map((dealer, index) => {
                tempDealerList.push({value: [dealer.id], label: dealer.name})
            })
        })

        props.tokenSafeAPIRequest('/user/search?limit=99999&active_only=false', requestOptions, '', (result) => {
            console.log(result)
            tempSubagentList.push({value: '', label: 'All Subagents'})
            result.map((subagent, index) => {
                tempSubagentList.push({value: [subagent.id], label: subagent.name})
            })
        })

        setFilterData([
            {
                key: 'subagent_IDs',
                options: tempSubagentList,
                selectedValue: '',
                selectedDisplay: 'Subagent'
            },
            {
                key: 'state_code',
                options: state_list,
                selectedValue: '',
                selectedDisplay: 'State'
            },
            {
                key: 'dealership_IDs',
                options: tempDealerList,
                selectedValue: '',
                selectedDisplay: 'Dealership'
            },
            {
                key: 'resource_state',
                options: [
                    {value: '', label: 'All'},
                    {value: 1, label: 'Active'},
                    {value: 0, label: 'Not Active'},
                ],
                selectedValue: '',
                selectedDisplay: 'Active'
            },
        ])
    },[])

    const [filterData, setFilterData] = useState([
        {
            key: 'subagent',
            options: [],
            selectedValue: '',
            selectedDisplay: 'Subagent'
        },
        {
            key: 'state',
            options: [],
            selectedValue: '',
            selectedDisplay: 'State'
        },
        {
            key: 'dealership',
            options: [],
            selectedValue: '',
            selectedDisplay: 'Dealership'
        },
        {
            key: 'resource_state',
            options: [
                {value: 1, label: 'Active'},
                {value: 0, label: 'Not Active'},
                {value: '', label: 'All'},
            ],
            selectedValue: '',
            selectedDisplay: 'Active'
        },
    ])

    const handleFilterDataChange = (index, value) => {
        setFilterData((filterData) => filterData.map((filter, index2) => {
            if (index2 === index)
            {
                filter.selectedValue = value.value;
                filter.selectedDisplay = value.label;
            }
            return filter;
        }))
        refreshAllData()
    }
    
    const searchElements = [
        (props) => (<div><TextInput placeholder='Search' colors={props.colors} onChange={(e) => onSearchElementUpdate(e, props.index)} prefix="" width={270} data={searchElementValues[0]}></TextInput></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(0, e)} index={props.index} indexParent={props.indexParent} data={filterData[0].options} width={195} value={filterData[0].selectedDisplay}></Dropdown></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(1, e)} index={props.index} indexParent={props.indexParent} data={filterData[1].options} width={195} value={filterData[1].selectedDisplay}></Dropdown></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(2, e)} index={props.index} indexParent={props.indexParent} data={filterData[2].options} width={195} value={filterData[2].selectedDisplay}></Dropdown></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(3, e)} index={props.index} indexParent={props.indexParent} data={filterData[3].options} width={195} value={filterData[3].selectedDisplay}></Dropdown></div>),
    ]

    const [searchElementValues, setSearchElementValues] = useState([
        '',
    ])

    const onSearchElementUpdate = (e, indexParent) => {
        setSearchElementValues((searchElementValues) => 
            searchElementValues.map((value, index) => 
                indexParent === index ? e : value
        ));

        // Reset the debounce timer
        if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
        }

        debounceTimerRef.current = setTimeout(() => {
            console.log('Searching Spiffees')
            searchSpiffees(e, 31, true);
        }, 1000); // 3000 ms = 3 seconds
    }

    useEffect(() => {
        return () => {
            if (debounceTimerRef.current) {
                clearTimeout(debounceTimerRef.current);
            }
        };
    }, []);

    
    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Edit Spiffee',
            displayElement: (props) => (<IconButton onClick={() => {setEditSpiffeeModal(true); setSelectedSpiffee(props.index)}} Style={{backgroundColor: props.colors.primary}} size={31} image={Edit} colors={props.colors}></IconButton>),
            default: '',
        },
        {
            title: 'Code',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Name',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Position',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Dealers',
            displayElement: (props) => (<Button onClick={() => {setEditDealersModal(true); setSelectedSpiffee(props.index)}} colors={props.colors} Style={{borderRadius: '5px'}}>Edit</Button>),
            default: undefined,
        },
        {
            title: 'Address',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Active',
            displayElement: (props) => (<div onClick={() => {editActive(props.index, props.tableData, props.tableIDs, props.skip, props.searchElement); onTableChange(!props.data, props.indexParent, props.index, props.tableData, props.tableIDs)}} style={{cursor: 'pointer', width: '28px', height: '28px', border: props.data === 1 ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.data === 1  ? props.colors.primary:''}}></div>),
            default: true,
        },
        {
            title: 'Subagents',
            displayElement: (props) => (<Button onClick={() => {setEditSubagentsModal(true); setSelectedSpiffee(props.index)}} colors={props.colors} Style={{borderRadius: '5px'}}>Edit</Button>),
            default: undefined,
        },
        {
            title: 'Phone Number',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Email',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Birthday',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Adjust',
            displayElement: (props) => (<Button onClick={() => {setEditBalanceModal(true); setSelectedSpiffee(props.index)}} colors={props.colors} Style={{borderRadius: '5px'}}>Adjust</Button>),
            default: '',
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default], 
        [tableTitles[4].default], 
        [tableTitles[5].default],
        [tableTitles[6].default],
        [tableTitles[7].default], 
        [tableTitles[8].default], 
        [tableTitles[9].default], 
        [tableTitles[10].default], 
        [tableTitles[11].default], 
    ])

    const [tableData, setTableData] = useState([
        [tableTitles[0].default],
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default],
        [tableTitles[4].default],
        [tableTitles[5].default],
        [tableTitles[6].default],
        [tableTitles[7].default],
        [tableTitles[8].default],
        [tableTitles[9].default],
        [tableTitles[10].default], 
        [tableTitles[11].default], 
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
        [[]], 
        [[]], 
        [[]],
        [[]],
        [[]],
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e, x, y) => {
        console.log(e, x, y)
    }

    const [editSpiffeeModal, setEditSpiffeeModal] = useState(false)
    const [editSubagentsModal, setEditSubagentsModal] = useState(false)
    const [createNewSpiffeeModal, setCreateNewSpiffeeModal] = useState(false)
    const [editDealersModal, setEditDealersModal] = useState(false)

    const searchSpiffees = (e, preloadedSpiffees, typedSearch) => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        console.log('Searching By: ' + e)

        let formattedFilters = {}
        filterData.map((filter) => {
            if (filter.selectedValue === '')
            {

            }
            else
            {
                if (filter.key === 'contract_type')
                {
                    console.log(filter.selectedValue)
                    if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                    {
                        formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                    else
                    {
                        formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                }
                formattedFilters[filter.key] = filter.selectedValue
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...formattedFilters,
            name: e
        })
        };

        props.tokenSafeAPIRequest('/spiffee/search?limit=' + preloadedSpiffees + '&fetch_links=true&reverse_sort=false&active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)

            let tempSpiffees = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            let tempSpiffeesData = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ];

            setSpiffees(result);

            result.map((spiffee, index) => {
                tempSpiffees[0].push('')
                tempSpiffees[1].push(spiffee.code)
                tempSpiffees[2].push(spiffee.name)
                tempSpiffees[3].push(spiffee.position)
                tempSpiffees[4].push(spiffee.dealership_IDs)
                tempSpiffees[5].push((spiffee.city === null ? '':spiffee.city) + ', ' + (spiffee.state_code === null ? '':spiffee.state_code))
                tempSpiffees[6].push(spiffee.resource_state)
                tempSpiffees[7].push(spiffee.subagent_IDs)
                tempSpiffees[8].push(spiffee.phone_number)
                tempSpiffees[9].push(spiffee.email)
                tempSpiffees[10].push(spiffee.birthday)
                tempSpiffees[11].push('')

                tempSpiffeesData[0].push('')
                tempSpiffeesData[1].push(spiffee.code)
                tempSpiffeesData[2].push(spiffee.id)
                tempSpiffeesData[3].push(spiffee.position)
                tempSpiffeesData[4].push(spiffee.dealership_IDs)
                tempSpiffeesData[5].push((spiffee.city === null ? '':spiffee.city) + ', ' + (spiffee.state_code === null ? '':spiffee.state_code))
                tempSpiffeesData[6].push(spiffee.resource_state)
                tempSpiffeesData[7].push(spiffee.subagent_IDs)
                tempSpiffeesData[8].push(spiffee.phone_number)
                tempSpiffeesData[9].push(spiffee.email)
                tempSpiffeesData[10].push(spiffee.birthday)
                tempSpiffeesData[11].push('')
            })

            setTableValues(tempSpiffees)
            setTableData(tempSpiffeesData)
        })

        if (typedSearch)
        {
            console.log('Resetting Skip')
            setSkip(30)
        }
    }

    function setEmptyStringsToNull(obj) {
        Object.keys(obj).forEach(key => {
            if (obj[key] === '') {
                obj[key] = null;
            }
        });
        return obj;
    }

    const addNewSpiffe = (spiffeeData) => {
        console.log(spiffeeData)
        
        spiffeeData = setEmptyStringsToNull(spiffeeData);

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(spiffeeData)
        };

        props.tokenSafeAPIRequest('/spiffee', requestOptions, tokenValues, (result, status) => {
            if (status === 200)
            {
                setCreateNewSpiffeeModal(false)
                setNotification('Created Spiffee Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }  

    const editSpiffee = (spiffeeData) => {
        console.log(spiffeeData)

        if (!spiffeeData.corporate_spiffee)
        {
            delete spiffeeData.name
        }

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(spiffeeData)
        };

        console.log(spiffeeData)

        props.tokenSafeAPIRequest('/spiffee/' + spiffeeData.id, requestOptions, tokenValues, (result, status) => {
            if (status === 200)
            {
                setEditSpiffeeModal(false)
                setNotification('Edited Spiffee Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const editDealers = (dealers) => {
        console.log(dealers)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                dealership_IDs: dealers[1]
            })
        };

        props.tokenSafeAPIRequest('/spiffee/' + spiffees[selectedSpiffee].id, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200) 
            {
                setEditDealersModal(false)
                setNotification('Edited Spiffee Dealers Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const deleteSpiffee = () => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/spiffee/' + spiffees[selectedSpiffee].id, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setEditSpiffeeModal(false)
                setNotification('Deleted Spiffee Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const editSubagents = (agents) => {
        console.log(agents)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                subagent_IDs: agents[1]
            })
        };

        props.tokenSafeAPIRequest('/spiffee/' + spiffees[selectedSpiffee].id, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200) 
            {
                setEditSubagentsModal(false)
                setNotification('Edited Spiffee Subagents Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const editActive = (rowIndex, tableValues, tableData, currentSkipValue, searchValue) => {

        console.log(transposeArray(tableData)[rowIndex][2])

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({})
        };

        console.log(transposeArray(tableData)[rowIndex][6])
        console.log(currentSkipValue)

        if (transposeArray(tableData)[rowIndex][6] === 1)
        {
            console.log('Deactivating')
            props.tokenSafeAPIRequest('/spiffee/deactivate/' + transposeArray(tableData)[rowIndex][2], requestOptions, tokenValues, (result, status) => {
                console.log(result)
                if (status === 200)
                {
                    setNotification('Deactivated Spiffee Successfully at: ' + getCurrentTimeFormatted())
                    refreshData(currentSkipValue, searchValue)
                } else {setNotification(props.getServerResponse(result))}
            })
        }
        else
        {
            console.log('Activating')
            props.tokenSafeAPIRequest('/spiffee/activate/' + transposeArray(tableData)[rowIndex][2], requestOptions, tokenValues, (result, status) => {
                console.log(result)
                if (status === 200)
                {
                    setNotification('Activated Spiffee Successfully at: ' + getCurrentTimeFormatted())
                    refreshData(currentSkipValue, searchValue)
                } else {setNotification(props.getServerResponse(result))}
            })
        }
    }

    const [notification, setNotification] = useState('')

    const [editBalanceModal, setEditBalanceModal] = useState(false)

    const adjustSpiffee = (data) => {
        console.log(data)

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                amount: data.amount,
                reason: data.reason,
                dealership_spiff: false,
            })
        };

        props.tokenSafeAPIRequest('/spiffee/adjust/' + spiffees[selectedSpiffee].id, requestOptions, '', (result, status) => {
            console.log(result)
            if (status === 200) 
            {
                setEditBalanceModal(false)
                setNotification('Adjusted Spiffee Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const [exportSpiffees, setExportSpiffees] = useState(false)

    const exportKeys = [{id: 'code', label: 'Subagent Code'}, {id: 'first_name', label: 'First Name'}, {id: 'midde_name', label: 'Middle Name'}, {id: 'last_name', label: 'Last Name'}, {id: 'position', label: 'Position'}, {id: 'address', label: 'Address'}, {id: 'city', label: 'City'}, {id: 'state_code', label: 'State'}, {id: 'zip_code', label: 'Zip Code'}, {id: 'SSN', label: 'SSN/ITIN'}, {id: 'resource_state', label: 'Active'}, {id: 'phone_number', label: 'Phone Number'}, {id: 'birthday', label: 'Birthday'}]

    const exportPostProcessing = (result) => {
        console.log(result)
        result.map((spiffee) => {
            let tempDealers = []
            let tempSubagents = []
            spiffee.ssn = () => {
                if (spiffee.SSN === null)
                {
                    return spiffee.ITIN
                }
                else {return spiffee.ssn}
            }
            spiffee.dealership_IDs.map((dealer) => {
                tempDealers.push(dealer.name + ', ')
                // tempUserConnections.push('Role: ' + user.connection_type + ' | Name:' + user.user_ID.first_name + ' ' + user.user_ID.last_name);
            })
            spiffee.subagent_IDs.map((subagent) => {
                tempSubagents.push(subagent.first_name + ' ' + subagent.last_name + ', ')
                // tempUserConnections.push('Role: ' + user.connection_type + ' | Name:' + user.user_ID.first_name + ' ' + user.user_ID.last_name);
            })
            if (spiffee.corporate_spiffee)
            {
                spiffee.first_name = spiffee.name;
            }
            spiffee.subagents = tempSubagents;
            spiffee.dealers = tempDealers
            return spiffee;
        })
        return result;
    }

    return (
        <ManageLayout colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <ManageHeader colors={props.colors} searchElements={searchElements} buttonText={'Add New Spiffee'} buttonText2={'Export Spiffees'} title='Manage Spiffees' onClick={() => setCreateNewSpiffeeModal(true)} onClick2={() => setExportSpiffees(true)} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></ManageHeader>
            <ManageTable colors={props.colors} tableData={tableValues} tableIDs={tableData} tableInfo={tableTitles} dropdownData={dropdownData} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} onBottom={onBottom} selectMenu={false} skip={skip} searchElement={searchElementValues[0]}></ManageTable>
            <ModalManager colors={props.colors}>
                <EditSpiffeeModal colors={props.colors} isOpen={editSpiffeeModal} setIsOpen={() => setEditSpiffeeModal(false)} editSpiffee={editSpiffee} tableData={tableData} tableValues={tableValues} selectedSpiffee={selectedSpiffee} spiffees={spiffees} onDelete={deleteSpiffee}></EditSpiffeeModal>
                <EditSubagentsModal colors={props.colors} isOpen={editSubagentsModal} setIsOpen={() => setEditSubagentsModal(false)} editSubagents={editSubagents}  spiffees={spiffees} selectedSpiffee={selectedSpiffee} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></EditSubagentsModal>
                <CreateNewSpiffeeModal colors={props.colors} isOpen={createNewSpiffeeModal} setIsOpen={() => setCreateNewSpiffeeModal(false)} addNewSpiffee={addNewSpiffe}></CreateNewSpiffeeModal>
                <EditDealersModal colors={props.colors} isOpen={editDealersModal} setIsOpen={() => setEditDealersModal(false)} editDealers={editDealers} selectedSpiffee={selectedSpiffee} tableData={tableData} tableValues={tableValues} tokenSafeAPIRequest={props.tokenSafeAPIRequest} spiffees={spiffees}></EditDealersModal>
                <EditBalanceModal colors={props.colors} isOpen={editBalanceModal} setIsOpen={() => setEditBalanceModal(false)} adjustSpiffee={adjustSpiffee} spiffees={spiffees} selectedSpiffee={selectedSpiffee} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></EditBalanceModal>
                <ExportData colors={props.colors} isOpen={exportSpiffees} setIsOpen={() => setExportSpiffees(false)} url={'/spiffee/search'} postProcessing={exportPostProcessing} filterData={{}} tokenSafeAPIRequest={props.tokenSafeAPIRequest} exportKeys={exportKeys} fileTitle={'List of all Spiffees Export'} calStartDate={'1900-01-01'} calEndDate={'2100-01-01'}></ExportData>
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </ManageLayout>
    )
}

export default Main
