import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

import Delete from '../../media/icons/icons8-delete-96.png'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    const [data, setData] = useState({
        name: '',
        DBAName: '',
        subagent: '',
        code: '',
        signedIncentiveForm: false,
        FIVisit: false,
        franchise: false,
        insideSales: false,
    })

    const [displayData, setDisplayData] = useState({
        name: '',
        DBAName: '',
        subagent: '',
        code: '',
        signedIncentiveForm: false,
        FIVisit: false,
        franchise: false,
        insideSales: false,
    })

    const onDataChange = (e, value) => {
        console.log(e, value)
        if (value === 'subagent')
        {
            console.log('trying to change a subagent I see!')
            setDisplayData(prevData => ({
                ...prevData,
                [value]: e.label
            }));
            setData(prevData => ({
                ...prevData,
                [value]: e.value
            }))
        }
        else
        {
            setDisplayData(prevData => ({
                ...prevData,
                [value]: e
            }));
            setData(prevData => ({
                ...prevData,
                [value]: e
            }))
        }
    }

    const [agentList, setAgentList] = useState([])
    const [adminList, setAdminList] = useState([])
    const [selectedAdmins, setSelectedAdmins] = useState([])

    const [cookies, setCookies] = useCookies([])

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + cookies['access_token']);

        var raw = JSON.stringify(
            {subagent: true}
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        props.tokenSafeAPIRequest('/user/search?limit=9999999', requestOptions, tokenValues, (result) => {
            let dealers = []
            result.map((agent, index) => {
                dealers.push({value: agent.id, label: agent.first_name + " " + agent.last_name})
            })
            console.log(dealers)
            setAgentList(dealers)
        })

        props.tokenSafeAPIRequest('/admin/search?limit=9999999', requestOptions, tokenValues, (result) => {
            let admins = []
            result.map((admin, index) => {
                admins.push({value: admin.id, label: admin.code})
            })
            console.log(admins)
            setAdminList(admins)
        })
    },[])

    const updateSelectedAdmins = (adminId) => {
        console.log(adminId)
        setSelectedAdmins((prevSelectedAdmins) => {
            if (prevSelectedAdmins.includes(adminId)) {
                // If the ID exists, remove it
                return prevSelectedAdmins.filter(id => id !== adminId);
            } else {
                // If the ID does not exist, add it
                return [...prevSelectedAdmins, adminId];
            }
        });
    };
    

    return (
        <div style={{width: '1000px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Create New Dealer</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                    <span style={{fontWeight: '500'}}>Name</span>
                    <TextInput onChange={(e) => onDataChange(e, 'name')} colors={props.colors} data={displayData.name} prefix=''></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '10px'}}>
                    <span style={{fontWeight: '500'}}>DBA Name</span>
                    <TextInput onChange={(e) => onDataChange(e, 'DBAName')}colors={props.colors} data={displayData.DBAName} prefix=''></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '10px'}}>
                    <span style={{fontWeight: '500'}}>Subagent Owner</span>
                    <Dropdown onChange={(e) => onDataChange(e, 'subagent')} colors={props.colors} data={agentList} value={displayData.subagent}></Dropdown>
                </div>
                <span style={{fontWeight: '500', marginTop: '15px'}}>Supported Weekly 7 Admins</span>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '5px', flexWrap: 'wrap'}}>
                    {adminList.map((admin, index) => {
                        return (
                            <div onClick={() => updateSelectedAdmins(admin.value)} style={{display: 'flex', flexDirection: 'column', marginLeft: '15px', marginleft: '15px', marginTop: '10px', cursor: 'pointer'}}>
                                <span>{admin.label}</span>
                                <div style={{width: '20px', height: '20px', border: selectedAdmins.includes(admin.value) === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '3px', backgroundColor: selectedAdmins.includes(admin.value) === true  ? props.colors.primary:''}}></div>
                            </div>
                        )
                    })}
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
                    <div onClick={() => onDataChange(!displayData.signedIncentiveForm, 'signedIncentiveForm')} style={{cursor: 'pointer', width: '20px', height: '20px', border: displayData.signedIncentiveForm === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '3px', backgroundColor: displayData.signedIncentiveForm === true  ? props.colors.primary:''}}></div>
                    <span style={{marginLeft: '10px'}}>Signed Incentive Form</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div onClick={() => onDataChange(!displayData.FIVisit, 'FIVisit')} style={{cursor: 'pointer', width: '20px', height: '20px', border: displayData.FIVisit === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '3px', backgroundColor: displayData.FIVisit === true  ? props.colors.primary:''}}></div>
                    <span style={{marginLeft: '10px'}}>F&I Trainer Visit</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div onClick={() => onDataChange(!displayData.franchise, 'franchise')} style={{cursor: 'pointer', width: '20px', height: '20px', border: displayData.franchise === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '3px', backgroundColor: displayData.franchise === true  ? props.colors.primary:''}}></div>
                    <span style={{marginLeft: '10px'}}>Franchise Dealer</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div onClick={() => onDataChange(!displayData.insideSales, 'insideSales')} style={{cursor: 'pointer', width: '20px', height: '20px', border: displayData.insideSales === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '3px', backgroundColor: displayData.insideSales === true  ? props.colors.primary:''}}></div>
                    <span style={{marginLeft: '10px'}}>Inside Sales</span>
                </div>
                <Button onClick={(e) => {props.onSubmit({...data, ...{selectedAdmins: selectedAdmins}}); props.refreshData()}} colors={props.colors} active={true} Style={{marginTop: '15px'}}>Save</Button>
            </div>
        </div>
    )
}

export default Main
