import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import DownArrow from '../media/icons/Down_Arrow.svg'

import {useFloating, autoUpdate} from '@floating-ui/react';

import Select from 'react-select';

const Main = (props) => {

    {/*
    Documentation
        Props:
            default={false/true} - Enables default styles entirely - True by default
            Style={...styles} - Passthrough to styles for complete control - empty by default
            maxShow={number} - Maximum values visible
            onChange={function} - Calls function when selected option is changed (Passes in selected option name)
            width={number} - this is set outside of Styles as it is needed
    */}
    
    const [selected, setSelected] = useState(props.children)

    const [customStyles, setCustomStyles] = useState({
        control: (provided, state) => ({
          ...provided,
          width: props.width,
          fontWeight: '500',
          color: 'black',
          fontFamily: "Montserrat, Arial, sans-serif",
          ...props.Style,
        }),
      });

    const onValChange = (e) => {
        props.onChange(e, props.index, props.indexParent)
    }

    const sortByLabelWithSpecificMatchesOnTop = (array) => {
        const priorityLabels = [
            'All Subagents',
            'All Dealers',
            'All Dealerships',
            'All Subagents on Dealership',
            'All Agents',
            'All Admins',
            'All Spiffees',
            'All States'
        ];

        if (array !== '' || array[0] === '')
        {
            return array?.sort((a, b) => {
                const aIsPriority = priorityLabels.includes(a.label);
                const bIsPriority = priorityLabels.includes(b.label);
        
                // Place items with priority labels at the beginning
                if (aIsPriority && !bIsPriority) return -1;
                if (!aIsPriority && bIsPriority) return 1;
        
                // If both have priority labels or neither has it, sort alphabetically
                return a.label.localeCompare(b.label);
            });
        }
    };

    return (
        <>
            <Select
                isSearchable
                styles={customStyles}
                defaultValue={selected}
                onChange={(e) => onValChange(e)}
                options={sortByLabelWithSpecificMatchesOnTop(props.data)}
                value={{value: props.value, label: props.value }}
                isDisabled={props.isDisabled}
                maxMenuHeight={200}
                menuPlacement={props.menuPlacement}
            />
        </>

    )
}

export default Main
