import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import OutputLayoutColumn from '../elements/OutputLayoutColumn'

import Sidebar from '../elements/Sidebar'
import Controller from '../elements/Controller'
import OutputColumn from '../elements/OutputColumn'
import Button from '../elements/Button'
import OutputHeader from '../elements/OutputHeader'
import OutputFilter from '../elements/OutputFilter'
import OutputBarChart from '../elements/OutputBarChart'
import OutputTable from '../elements/OutputTable'

import FiltersModal from '../assets/metrics/FiltersModal'
import ModalManager from '../elements/ModalManager'

import Dropdown from '../elements/ReactSelectDropdown'

import ExportData from '../assets/metrics/ExportData'

import CalendarModal from '../assets/metrics/CalendarModal'

import { useCookies } from 'react-cookie'

import WeekSelector from '../elements/WeekSelector'

const Main = (props) => {

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const list = ['4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors']

    const filterValues = ['Liberty Shield','VSC','Weekly 7','4 Season Motors']

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Quantity',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Admin',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Dealer',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Report Type',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Contract Type',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Date',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'DPPP',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${props.data === null ? 0:formatNumber(props.data)}</span>),
            default: '',
        },
        {
            title: 'Gross',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${formatNumber(props.data)}</span>),
            default: 0,
        },
        {
            title: 'Net',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${formatNumber(props.data)}</span>),
            default: 0,
        },
    ])

    const formatDateFilters = (date) => {
        if (!(date instanceof Date)) {
            return '';
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString(); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${year}-${month}-${day}`;
    }

    const [calStartDate, setCalStartDate] = useState(('2000-01-01'))
    const [calEndDate, setCalEndDate] = useState(('2200-01-01'))

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default], 
        [tableTitles[4].default],
        [tableTitles[5].default],
        [tableTitles[6].default], 
        [tableTitles[7].default], 
        [tableTitles[8].default], 
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e,x,y) => {
        console.log(e,x,y)
    }

    const updateSelectedDealer = (dealer) => {
        console.log(dealer)
        setSelectedDealer(dealer)
    }

    const [cookies, setCookies] = useCookies([])
    const [selectedDealer, setSelectedDealer] = useState({value: '', label: 'All Dealers'})
    const [skip, setSkip] = useState(0)
    const [sort, setSort] = useState('timestamp')
    const [headerValues, setHeaderValues] = useState([])
    const [allDealers, setAllDealers] = useState([])
    const [filtersModal, setFiltersModal] = useState(false)
    const [filterData, setFilterData] = useState([
        {
            title: 'Admin',
            data: '',
            value: {label: '', value: ''},
            key: 'admin_ID',
        },
        {
            title: 'Report Type',
            data: [
                {value: 'Saturday', label: 'Saturday'},
                {value: 'Adjustment', label: 'Adjustment'},
                {value: 'Weekly 7 Reversal', label: 'Weekly 7 Reversal'},
                {value: 'Void', label: 'Void'},
                {value: 'Rejection', label: 'Rejection'},
                {value: 'Unwind', label: 'Unwind'},
                {value: 'Cancellation', label: 'Cancellation'},
                {value: 'Over / Under', label: 'Over / Under'},
                {value: 'Payback', label: 'Payback'},
                {value: 'Claim', label: 'Claim'},
                {value: '100% Cancellation', label: '100% Cancellation'},
            ],
            value: {label: '', value: ''},
            key: 'report_type',
        },
        {
            title: 'Contract Type',
            data: '',
            value: {label: '', value: ''},
            key: 'contract_type',
        },
        {
            title: 'Subagent',
            data: '',
            value: {label: '', value: ''},
            key: 'subagent_ID',
        },
    ])

    function formatDate(date) {
        if (!(date instanceof Date)) {
            return '';
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString().substr(-2); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${month}/${day}/${year}`;
    }

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    function getDateWeeksAgo(weeks) {
        const today = new Date();
        const daysAgo = weeks * 7; // Convert weeks to days
        today.setDate(today.getDate() - daysAgo); // Subtract the days
    
        // Format the date as yyyy-mm-dd
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, add 1 to get the correct month
        const day = today.getDate().toString().padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    }

    //test getting graph data
    const getGraphData = () => {

    }
    // const getGraphData = (week, newData) => {
        // let tokenValues = {
        //     refresh_token: cookies['refresh_token'],
        //     refresh_token_expires: cookies['refresh_token_expires'],
        //     access_token: cookies['access_token'],
        //     access_token_expires: cookies['access_token_expires'],
        // }

        // //get dealers
        // var myHeaders = new Headers();
        // myHeaders.append("Accept", "application/json");
        // myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        // myHeaders.append("Content-Type", "application/json");

        // let filterDataBy = {}
        // let startDate = ''
        // filterData.map((category, index) => {
        //     if (category.key === 'contract_type')
        //     {
        //         filterDataBy = {
        //             ...filterDataBy, 
        //             contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
        //             contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
        //         }
        //     }
        //     else if (category.key === 'date')
        //     {
        //         const today = new Date();
        //         const result = new Date(today);
        //         if (category.value.value === '')
        //         {
        //             result.setFullYear(today.getFullYear() - 100);
        //         }
        //         else
        //         {
        //             result.setDate(today.getDate() - category.value.value);
        //         }
        //         startDate = result      
        //     }
        //     else
        //     {
        //         filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
        //     }
        //})
    
    //     var requestOptions = {
    //       method: 'POST',
    //       headers: myHeaders,
    //       redirect: 'follow',
    //       body: JSON.stringify({
    //         dealership_ID: selectedDealer.value === '' ? null:selectedDealer.value,
    //         ...filterDataBy
    //       })
    //     }

    //     props.tokenSafeAPIRequest('/metrics/dealership?limit=1&fetch_links=true&sort_field=' + sort + '&start_date=' + getDateWeeksAgo(1) + '&end_date=' + formatDateFilters(startDate), requestOptions, tokenValues, (result1, status) => {
    //         props.tokenSafeAPIRequest('/metrics/dealership?limit=1&fetch_links=true&sort_field=' + sort + '&start_date=' + getDateWeeksAgo(2) + '&end_date=' +  getDateWeeksAgo(1), requestOptions, tokenValues, (result2, status) => {
    //             props.tokenSafeAPIRequest('/metrics/dealership?limit=1&fetch_links=true&sort_field=' + sort + '&start_date=' + getDateWeeksAgo(3) + '&end_date=' +  getDateWeeksAgo(2), requestOptions, tokenValues, (result3, status) => {
    //                 props.tokenSafeAPIRequest('/metrics/dealership?limit=1&fetch_links=true&sort_field=' + sort + '&start_date=' + getDateWeeksAgo(4) + '&end_date=' +  getDateWeeksAgo(3), requestOptions, tokenValues, (result4, status) => {
    //                     props.tokenSafeAPIRequest('/metrics/dealership?limit=1&fetch_links=true&sort_field=' + sort + '&start_date=' + getDateWeeksAgo(5) + '&end_date=' + getDateWeeksAgo(4), requestOptions, tokenValues, (result5, status) => {
    //                         props.tokenSafeAPIRequest('/metrics/dealership?limit=1&fetch_links=true&sort_field=' + sort + '&start_date=' + getDateWeeksAgo(6) + '&end_date=' +  getDateWeeksAgo(5), requestOptions, tokenValues, (result6, status) => {
    //                             props.tokenSafeAPIRequest('/metrics/dealership?limit=1&fetch_links=true&sort_field=' + sort + '&start_date=' + getDateWeeksAgo(7) + '&end_date=' +  getDateWeeksAgo(6), requestOptions, tokenValues, (result7, status) => {
    //                                 props.tokenSafeAPIRequest('/metrics/dealership?limit=1&fetch_links=true&sort_field=' + sort + '&start_date=' + getDateWeeksAgo(8) + '&end_date=' +  getDateWeeksAgo(7), requestOptions, tokenValues, (result8, status) => {
    //                                     props.tokenSafeAPIRequest('/metrics/dealership?limit=1&fetch_links=true&sort_field=' + sort + '&start_date=' + getDateWeeksAgo(9) + '&end_date=' + getDateWeeksAgo(8), requestOptions, tokenValues, (result9, status) => {
    //                                         props.tokenSafeAPIRequest('/metrics/dealership?limit=1&fetch_links=true&sort_field=' + sort + '&start_date=' + getDateWeeksAgo(10) + '&end_date=' +  getDateWeeksAgo(9), requestOptions, tokenValues, (result10, status) => {
    //                                             props.tokenSafeAPIRequest('/metrics/dealership?limit=1&fetch_links=true&sort_field=' + sort + '&start_date=' + getDateWeeksAgo(11) + '&end_date=' +  getDateWeeksAgo(10), requestOptions, tokenValues, (result11, status) => {
    //                                                 props.tokenSafeAPIRequest('/metrics/dealership?limit=1&fetch_links=true&sort_field=' + sort + '&start_date=' + getDateWeeksAgo(12) + '&end_date=' +  getDateWeeksAgo(11), requestOptions, tokenValues, (result12, status) => {
    //                                                     setGraphData([result1.total_net, result2.total_net, result3.total_net, result4.total_net, result5.total_net, result6.total_net, result7.total_net, result8.total_net, result9.total_net, result10.total_net, result11.total_net, result12.total_net])
    //                                                 })
    //                                             })
    //                                         })
    //                                     })
    //                                 })
    //                             })
    //                         })
    //                     })
    //                 })
    //             })
    //         })
    //     })
    // }

    const downloadCSV = (tableValues) => {
        // Column titles
        const headers = ['Code', 'Dealership', 'Role', 'Address', 'Contact'];
    
        // Function to format and escape the data
        const formatData = (data) => {
            return '"' + String(data).replace(/"/g, '""') + '"'; // Enclose in quotes and escape quotes
        };
    
        // Convert array of arrays to CSV
        const csvContent = [
            headers.join(','), // add header row
            ...tableValues.map(row => row.slice(0, -1).map(formatData).join(',')) // format each row and slice off the last element
        ].join('\n');
    
        // Create a blob from the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
    
        // Create a link and trigger download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'export.csv'); // Name the file here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({subagent: true})
        }

        let tempDealerList = []
        let tempAdminList = []
        let tempContractList = []
        let tempSubagentList = []

        //get dealerships
        props.tokenSafeAPIRequest('/dealership/names?active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)
            tempDealerList.push({value: '', label: 'All Dealers'})
            result.map((dealer, index) => {
                tempDealerList.push({value: dealer.id, label: dealer.name})
            })
            setAllDealers(tempDealerList)
        })

        //get admins
        props.tokenSafeAPIRequest('/admin/search?limit=99999&active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)
            result.map((admin, index) => {
                tempAdminList.push({value: admin.id, label: admin.name})
            })
        })

        props.tokenSafeAPIRequest('/user/search?limit=9999999&reverse_sort=false', requestOptions, tokenValues, (result) => {
            console.log(result)
            result.map((agent, index) => {
                tempSubagentList.push({value: agent.id, label: agent.first_name + " " + agent.last_name})
            })
        })

        requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
          }

        //get all contract types
        props.tokenSafeAPIRequest('/admin/contract_types?active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)
            result.VSC_contracts.map((type, index) => {
                tempContractList.push({value: 'VSC - ' + type, label: 'VSC - ' + type})
            })
            result.ancillary_contracts.map((type, index) => {
                tempContractList.push({value: 'Ancillary - ' + type, label: 'Ancillary - ' + type})
            })
        })

        getGraphData()

        setFilterData([
            {
                title: 'Admin',
                data: tempAdminList,
                value: {label: '', value: ''},
                key: 'admin_ID',
            },
            {
                title: 'Report Type',
                data: [
                    {value: 'Saturday', label: 'Saturday'},
                    {value: 'Adjustment', label: 'Adjustment'},
                    {value: 'Weekly 7 Reversal', label: 'Weekly 7 Reversal'},
                    {value: 'Void', label: 'Void'},
                    {value: 'Rejection', label: 'Rejection'},
                    {value: 'Unwind', label: 'Unwind'},
                    {value: 'Cancellation', label: 'Cancellation'},
                    {value: 'Over / Under', label: 'Over / Under'},
                    {value: 'Payback', label: 'Payback'},
                    {value: 'Claim', label: 'Claim'},
                    {value: '100% Cancellation', label: '100% Cancellation'},
                ],
                value: {label: '', value: ''},
                key: 'report_type',
            },
            {
                title: 'Contract Type',
                data: tempContractList,
                value: {label: '', value: ''},
                key: 'contract_type',
            },
            {
                title: 'Subagent',
                data: tempSubagentList,
                value: {label: '', value: ''},
                key: 'subagent_ID',
            },
        ])
    },[])

    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let filterDataBy = {}
        let startDate = ''
        filterData.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result      
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            dealership_ID: selectedDealer.value === '' ? null:selectedDealer.value,
            ...filterDataBy
          })
        }

        props.tokenSafeAPIRequest('/metrics/dealership?limit=15&fetch_links=true&sort_field=' + sort + '&start_date=' + calStartDate + '&end_date=' + calEndDate, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (result.reports.length === 0)
            {
                setTableValues([
                    [], 
                    [],
                    [],
                    [], 
                    [],
                    [],
                    [], 
                    [], 
                    [],
                ])
                let tempStats = [
                    {title: 'Total Dealerships', value: 0},
                    {title: 'Total IS Net', value: '$' + 0},
                    {title: 'Total Reports', value: 0},
                ]
                setHeaderValues(tempStats)
            }
            else
            {
                let tempStats = [
                    {title: 'Total Net', value: '$' + formatNumber(result.total_net)},
                    {title: 'Total DPPP', value: '$' + formatNumber(result.total_dppp)},
                    {title: 'Total GAP Plus', value: '$' + formatNumber(result.total_gap_plus)},
                    {title: 'Total Inside Sales', value: '$' + formatNumber(result.total_inside_sales)},
                    {title: 'Total Reports', value: formatNumber(result.total_reports)},
                ]
                setHeaderValues(tempStats)

                let tempTableValues = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [], 
                ]

                let tempReportID = []
                result.reports.map((report, index) => {
                    tempTableValues[0].push(report.quantity)
                    tempTableValues[1].push(report.admin_ID === null ? 'No Admin':report?.admin_ID?.name)
                    tempTableValues[2].push(report.dealership_ID === null ? 'No Dealership':report?.dealership_ID?.name)
                    tempTableValues[3].push(report.report_type)
                    tempTableValues[4].push(report.report_subtype === 'Adjustment' ? report.report_type:report.contract_type + ' - ' + report.contract_subtype)
                    tempTableValues[5].push(formatDate(new Date(report.date)))
                    tempTableValues[6].push(report.dppp_amount)
                    tempTableValues[7].push(report.contract_gross)
                    tempTableValues[8].push(report.net)
                    tempReportID.push([report.id, report.code])
                })
                
                setTableValues(tempTableValues)
            }
        })
        getGraphData()
        setSkip(0)
    },[selectedDealer, filterData, sort, calStartDate, calEndDate])

    const buildFilterData = (data) => {
        let filterDataBy = {}
        let startDate = ''
        data.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result      
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
        return {
            dealership_ID: selectedDealer.value === '' ? null:selectedDealer.value,
            start_date: startDate,
            ...filterDataBy
        }
    }

    const onBottom = () => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let filterDataBy = {}
        let startDate = ''
        filterData.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result      
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            dealership_ID: selectedDealer.value === '' ? null:selectedDealer.value,
            ...filterDataBy
          })
        }

        props.tokenSafeAPIRequest('/metrics/dealership?limit=15&fetch_links=true&skip=' + (skip+15) + '&sort_field=' + sort + '&start_date=' + calStartDate + '&end_date=' + calEndDate, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (result.reports.length === 0)
            {
                return ''
            }
            else
            {
                let tempTableValues = tableValues

                let tempReportID = []
                result.reports.map((report, index) => {
                    tempTableValues[0].push(report.quantity)
                    tempTableValues[1].push(report.admin_ID === null ? 'No Admin':report?.admin_ID?.name)
                    tempTableValues[2].push(report.dealership_ID === null ? 'No Dealership':report?.dealership_ID?.name)
                    tempTableValues[3].push(report.report_type)
                    tempTableValues[4].push(report.contract_type + " - " + report.contract_subtype)
                    tempTableValues[5].push(formatDate(new Date(report.date)))
                    tempTableValues[6].push(report.dppp_amount)
                    tempTableValues[7].push(report.contract_gross)
                    tempTableValues[8].push(report.net)
                    tempReportID.push([report.id, report.code])
                })
                
                setTableValues(tempTableValues)
            }
        })
        setSkip((skip) => skip + 15)
    }

    const saveFilters = (data, displayData) => {
        setFilterData((prevState, index) => {
            return prevState.map((item, index) => {
                return {...item, value: {value: data[item.key], label: displayData[item.key]}}
            })
        })
    } 

    const removeFilter = (key) => {
        setFilterData((prevState, index) => {
            return prevState.map((item, index) => {
                if (item.key === key)
                {
                    return {...item, value: {value: '', label: ''}}
                }
                else {return item}
            })
        })

        setFilterModalDisplayData((prevState) => {
            return {...prevState, [key]: ''}
        })

        setFilterModalData((prevState) => {
            return {...prevState, [key]: ''}
        })
    }

    const [filterModalDisplayData, setFilterModalDisplayData] = useState({
        admin_ID: '',
        report_type: '',
        contract_type: '',
        date: '',
    })

    const [filterModalData, setFilterModalData] = useState({
        admin_ID: '',
        report_type: '',
        contract_type: '',
        date: '',
    })

    const updateFilterModalData = (e, key) => {
        setFilterModalDisplayData(data => ({
            ...data,
            [key]: e.label
        }))
        setFilterModalData(data => ({
            ...data,
            [key]: e.value
        }))
    }

    const sortOptions = [
        { value: 'net', label: 'Net' },
        { value: 'timestamp', label: 'Date' },
    ]

    const sortToName = {
        timestamp: 'Date',
        net: 'Net',
    }

    const masterList = [
        {
            name: 'Export Report',
            link: '',
            onClick: () => {setExportDataModal(true)},
        },
        {
            name: 'Combined Dealer Report',
            link: '/output/combinedDealerReport',
            key: "CombinedDealerReport",
            onClick: () => {},
        },
        {
            name: 'Combined Subagent Report',
            link: '/output/combinedSubagentReport',
            key: "CombinedSubagentReport",
            onClick: () => {},
        },
        {
            name: 'Contract Count Report',
            link: '/output/contractCountReport',
            key: "ContractCountReport",
            onClick: () => {},
        },
        {
            name: 'Inside Sales Report',
            link: '/output/insidesalesreport',
            key: "InsideSalesReport",
            onClick: () => {},
        },
        // {
        //     name: '-List of Gift Cards',
        //     link: '/output/listOfGiftCards',
        //     key: "GiftCardList",
        //     onClick: () => {},
        // },
        {
            name: 'List of Reserve Input/Outputs',
            link: '/output/listOfReserveInputs',
            key: "ReserveAccounts",
            onClick: () => {},
        },
        // {
        //     name: '-List of Reserve Outputs',
        //     link: '/output/listOfReserveOutputs',
        //     key: "ReserveInputs",
        //     onClick: () => {},
        // },
        {
            name: 'List of Saturday Reports',
            link: '/output/listOfSaturdayReports',
            key: "SaturdayReport",
            onClick: () => {},
        },
        {
            name: 'New Dealer Signups',
            link: '/output/newDealerSignups',
            key: "NewDealerSignups",
            onClick: () => {},
        },
        // {
        //     name: '-Outstanding Pre-spiffs',
        //     link: '/output/outstandingPrespiffs',
        //     key: "OutstandingPrespiffs",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Recent Address Changes',
        //     link: '/output/recentAddressChanges',
        //     key: "RecentAddressChanges",
        //     onClick: () => {},
        // },
        {
            name: 'Reporting History',
            link: '/output/reportingHistory',
            key: "ReportingHistory",
            onClick: () => {},
        },
        // {
        //     name: '-Reserve Account Reports',
        //     link: '/output/reserveAccountReports',
        //     key: "ReserveAccountReport",
        //     onClick: () => {},
        // },
        {
            name: 'Spiffees Report',
            link: '/output/spiffeesReport',
            key: "SpiffeesReport",
            onClick: () => {},
        },
        // {
        //     name: '-Subagent Business',
        //     link: '/output/subagentBusiness',
        //     key: "SubagentBusiness",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Subagent Net & Inside Sales',
        //     link: '/output/subagentNetAndInsideSales',
        //     key: "SubagentNetInsideSales",
        //     onClick: () => {},
        // },
        {
            name: 'Void & Needs to be Replaced',
            link: '/output/voidAndNeedsReplaced',
            key: "VoidAndNeedsToBeReplaced",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Weeks List',
            link: '/output/reserveAccountWeeks',
            key: "ReserveAccountWeeks",
            onClick: () => {},
        },
        {
            name: 'Combined Spiffees Report',
            link: '/output/combinedSpiffeesReport',
            key: "CombinedSpiffeesReport",
            onClick: () => {},
        },
        {
            name: 'List of Expense Reports',
            link: '/output/expenseReports',
            key: "ListOfExpenseReports",
            onClick: () => {},
        },
        {
            name: 'List of Adjustment Reports',
            link: '/output/adjustmentReports',
            key: "ListOfAdjustmentReports",
            onClick: () => {},
        },
        {
            name: 'List of DPPP/GAP Plus',
            link: '/output/DPPPGAP',
            key: "ListOfDPPP/GAP",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Totals',
            link: '/output/reserveAccountTotals',
            key: "ReserveAccountTotals",
            onClick: () => {},
        },
        {
            name: 'Prespiff Paid Off',
            link: '/output/prespiffPaidOff',
            key: "PrespiffPaidOff",
            onClick: () => {},
        },
        {
            name: 'Dealer Totals',
            link: '/output/dealerTotals',
            key: "DealerTotals",
            onClick: () => {},
        },
    ]

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + cookies['access_token']);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/permission/me', requestOptions, '', (result, status) => {
            console.log(result) //'*':'*' - Superadmin
            let tempButtonList = [{
                name: 'Export Report',
                link: '',
                onClick: () => {setExportDataModal(true)},
            }]
            if (result.static_role === 'superadmin' || result.static_role === 'admin')
            {
                localStorage.setItem('button_list', JSON.stringify(masterList))
                setButtonList(masterList)
            }
            else
            {
                result.allow.Metrics.map((metric) => {
                    masterList.map((button) => {
                        if (metric === button.key)
                        {
                            tempButtonList.push(button)
                        }
                    })
                })
                tempButtonList = tempButtonList.sort((a,b) => {
                    if (a.name === "Export Report") return -1; // Move "Export Report" to the top
                    if (b.name === "Export Report") return 1;
                    return a.name.localeCompare(b.name);
                })
                localStorage.setItem('button_list', JSON.stringify(tempButtonList))
                setButtonList(tempButtonList)
            }
        })  
    },[])

    const [exportDataModal, setExportDataModal] = useState(false)
    const [buttonList, setButtonList] = useState(JSON.parse(localStorage.getItem('button_list')))

    const exportKeys = [{id: 'quantity', label: 'QTY'}, {id: 'admin_ID.name', label: 'Admin'}, {id: 'dealership_ID.name', label: 'Dealership'}, {id: 'report_type', label: 'Report Type'}, {id: 'contract_type', label: 'Contract Type'}, {id: 'timestamp', label: 'Date'}, {id: 'contract_gross', label: 'Gross'}, {id: 'net', label: 'Net'}]

    const graphKeys = [getDateWeeksAgo(1), getDateWeeksAgo(2), getDateWeeksAgo(3), getDateWeeksAgo(4), getDateWeeksAgo(5), getDateWeeksAgo(6), getDateWeeksAgo(7), getDateWeeksAgo(8), getDateWeeksAgo(9), getDateWeeksAgo(10), getDateWeeksAgo(11), getDateWeeksAgo(12)]

    const [graphData, setGraphData] = useState([])

    const [selectDatesModal, setSelectDatesModal] = useState(false)

    const filterDate = (start, end) => {
        if (start.getTime() > end.getTime())
        {
            
        }
        else
        {
            start = formatDateFilters(start)
            end = formatDateFilters(end)
            setCalStartDate(start)
            setCalEndDate(end)
        }
    }

    return (
        <OutputLayoutColumn colors={props.colors}>
            <Sidebar colors={props.colors} tab={1}></Sidebar>
            <Controller colors={props.colors} tokenSafeAPIRequest={props.tokenSafeAPIRequest} activeButton={true} buttonList={buttonList} title={"Outputs"}></Controller>
            <OutputColumn colors={props.colors} list={list} title={"Dealers"}></OutputColumn>
            <div> {/*THERE IS A HIDDEN STYLE TAG FROM THE OUTPUT LAYOUT --- width: "auto", marginLeft: '20px', display: 'flex', flexDirection: 'column'*/}
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Dropdown colors={props.colors} onChange={(e) => updateSelectedDealer(e)} index={props.index} indexParent={props.indexParent} data={allDealers} width={195} value={selectedDealer.label}>Test</Dropdown>
                    <span style={{fontWeight: '600', marginLeft: '20px', marginTop: '6px'}}>Filter Dealer</span>
                    <WeekSelector colors={props.colors} onClick={() => setSelectDatesModal(true)} active={true} calStartDate={calStartDate} calEndDate={calEndDate} Style={{marginLeft: 'auto', marginRight: '20px'}}>{calStartDate === '2000-01-01' ? '':calStartDate + ' | '} Select Week | {calEndDate === '2200-01-01' ? 'All Times':calEndDate}</WeekSelector>
                </div>
                <OutputHeader colors={props.colors} headerValues={headerValues}></OutputHeader>
                <OutputFilter colors={props.colors} onClick={() => setFiltersModal(true)} filterData={filterData} removeFilter={removeFilter} setSort={setSort} sort={sort} sortOptions={sortOptions} sortToName={sortToName}></OutputFilter>
                <OutputBarChart colors={props.colors} title={'Net'} graphKeys={graphKeys} graphData={graphData}></OutputBarChart>
                <OutputTable colors={props.colors} tableData={tableValues} tableInfo={tableTitles} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false} onBottom={onBottom}></OutputTable>
            </div>
            <ModalManager colors={props.colors}>
                <FiltersModal colors={props.colors} isOpen={filtersModal} setIsOpen={() => setFiltersModal(false)} filterData={filterData} saveFilters={saveFilters} displayData={filterModalDisplayData} data={filterModalData} updateData={updateFilterModalData}></FiltersModal>
                <ExportData colors={props.colors} isOpen={exportDataModal} calStartDate={calStartDate} calEndDate={calEndDate} setIsOpen={() => setExportDataModal(false)} filterData={buildFilterData(filterData)} url={'/metrics/dealership'} sort={sort} tokenSafeAPIRequest={props.tokenSafeAPIRequest} exportKeys={exportKeys} fileTitle={'Combined Dealer Report'}></ExportData>
                <CalendarModal colors={props.colors} isOpen={selectDatesModal} calStartDate={calStartDate} calEndDate={calEndDate} setIsOpen={() => setSelectDatesModal(false)} filterDate={filterDate}></CalendarModal>
            </ModalManager>
        </OutputLayoutColumn>
    )
}

export default Main
