import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'
import Table from '../../elements/Table'

import GoTo from '../../media/icons/icons8-share-96.png'

const Main = (props) => {

    console.log(props)

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Quantity',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Agent / Spiffee',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Dealer',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Account',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Type',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Amount',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${formatNumber(props.data)}</span>),
            default: '',
        },
        {
            title: 'Date',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Reason',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default], 
        [tableTitles[3].default],
        [tableTitles[4].default], 
        [tableTitles[5].default],
        [tableTitles[6].default], 
        [tableTitles[7].default],
    ])

    const [tableData, setTableData] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default], 
        [tableTitles[3].default],
        [tableTitles[4].default], 
        [tableTitles[5].default],
        [tableTitles[6].default], 
        [tableTitles[7].default],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
    ])

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e,x,y) => {
        console.log(e,x,y)
    }

    const [accounts, setAccounts] = useState([])

    const convertPayeeToUser = (payee, user, spiffee, dealership) => {
        if (payee === null) {return 'No User'}
        if (payee === 0) {return spiffee.name}
        if (payee === 1) {return dealership.name}
        if (payee === 2) {return user === null ? 'No Agent':(user?.first_name + ' ' + user?.last_name)}
        if (payee === 3) {return 'Inside Sales'}
    }

    function formatDate(date) {
        if (!(date instanceof Date)) {
            throw new Error('Input must be a Date object');
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString().substr(-2); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${month}/${day}/${year}`;
    }

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        props.tokenSafeAPIRequest('/metrics/combined_subagent_report/transactions/' + props?.selectedReport + '?fetch_links=true&limit=10', requestOptions, '', (result, status) => {
            console.log(result)
            setAccounts(result)

            let tableDataArray = []

            result.map((accounts, index) => {
                let tempTableValues = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                ]

                accounts.transactions.map((transfer) => {
                    tempTableValues[0].push(transfer.quantity)
                    tempTableValues[1].push(convertPayeeToUser(transfer.payee, transfer.user_ID, transfer.spiffee_ID, transfer.dealership_ID))
                    tempTableValues[2].push(transfer.dealership_ID === null ? 'No Dealership':transfer.dealership_ID.name)
                    tempTableValues[3].push(transfer.account_ID.name)
                    tempTableValues[4].push(transfer.amount > 0 ? 'Input':'Output')
                    tempTableValues[5].push(transfer.amount)
                    tempTableValues[6].push(formatDate(new Date(transfer.timestamp)))
                    tempTableValues[7].push(transfer.reason)
                    // tempTableValues[8].push(transfer)
                })
                tableDataArray.push(tempTableValues)
            })

            setAccountsTableData(tableDataArray)

            console.log(tableDataArray)
        })
    },[props.selectedReport])

    const [tab, setTab] = useState(0)
    const [accountsTableData, setAccountsTableData] = useState([])

    const generateKey = () => {
        let tempNum = Math.random() * 100000
        console.log(tempNum)
        return (Math.round(tempNum) + 'F') ;
    }

    console.log(accounts)

    return (
        <div style={{width: 'auto', height: 'auto', display: 'flex', flexDirection: 'column', maxWidth: '1400px'}}>
            {/*Header*/}

            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>View Account Ledgers</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', height: '35px', marginTop: '20px'}}>
                {accounts.map((account, index) => {
                    return <Button onClick={() => setTab(index)} colors={props.colors} active={tab === index ? true:false} Style={{flexGrow: 1, marginRight: '5px', marginRight: '20px'}}>{account.name}</Button>
                })}
            </div>

            {accounts.map((account, index) => {
                return tab === index ? (
                    <div key={index} style={{ display: 'flex', flexDirection: 'column', height: '500px' }}>
                        <Table colors={props.colors} keys={generateKey()} tableData={accountsTableData[index]} tableIDs={accountsTableData[index]} tableInfo={tableTitles} dropdownData={dropdownData} onChange={onTableChange} selectMenu={false} key='Modal'></Table>
                    </div>
                ) : null;
            })}

            <Button onClick={() => props.setIsOpen()} colors={props.colors} active={true} Style={{marginLeft: 'auto', width: '150px', marginTop: '15px'}}>Close</Button>
        </div>
    )
}

export default Main
