import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import OutputLayoutColumn from '../elements/OutputLayoutColumn'

import Sidebar from '../elements/Sidebar'
import Controller from '../elements/Controller'
import OutputColumn from '../elements/OutputColumn'
import Button from '../elements/Button'
import OutputHeader from '../elements/OutputHeader'
import OutputFilter from '../elements/OutputFilter'
import OutputBarChart from '../elements/OutputBarChart'
import OutputTable from '../elements/OutputTable'

import ModalManager from '../elements/ModalManager'

import Dropdown from '../elements/ReactSelectDropdown'

import FiltersModal from '../assets/metrics/FiltersModal'

import ExportData from '../assets/metrics/ExportData'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const list = ['Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski']

    const [cookies, setCookies] = useCookies([])

    const [headerValues, setHeaderValues] = useState([])
    const [allAgents, setAllAgents] = useState([])
    const [selectedAgent, setSelectedAgent] = useState({value: '', label: 'All Agents'})
    const [skip, setSkip] = useState(0)

    const [filtersModal, setFiltersModal] = useState(false)
    const [sort, setSort] = useState('timestamp')

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    
    const [filterData, setFilterData] = useState([
        {
            title: 'Subagent',
            data: '',
            value: {label: '', value: ''},
            key: 'subagent_ID',
        },
        {
            title: 'Admin',
            data: '',
            value: {label: '', value: ''},
            key: 'admin_ID',
        },
        {
            title: 'Dealership',
            data: '',
            value: {label: '', value: ''},
            key: 'dealership_ID',
        },
        {
            title: 'Report Type',
            data: [
                {value: 'Saturday', label: 'Saturday'},
                {value: 'Adjustment', label: 'Adjustment'},
                {value: 'Weekly 7', label: 'Weekly 7'},
                {value: 'Void', label: 'Void'},
                {value: 'Rejection', label: 'Rejection'},
                {value: 'Unwind', label: 'Unwind'},
                {value: 'Cancellation', label: 'Cancellation'},
                {value: 'Over / Under', label: 'Over / Under'},
                {value: 'Payback', label: 'Payback'},
                {value: 'Claim', label: 'Claim'},
                {value: '100% Cancellation', label: '100% Cancellation'},
            ],
            value: {label: '', value: ''},
            key: 'report_type',
        },
        {
            title: 'Contract Type',
            data: '',
            value: {label: '', value: ''},
            key: 'contract_type',
        },
        {
            title: 'Date',
            data: [
                {value: 7, label: '1 Week'},
                {value: 30, label: '1 Month'},
                {value: 182, label: '6 Months'},
                {value: 365, label: '1 Year'},
            ],
            value: {label: '', value: ''},
            key: 'date',
        },
    ])

    //
    // useEffect(() => {
    //     let tokenValues = {
    //         refresh_token: cookies['refresh_token'],
    //         refresh_token_expires: cookies['refresh_token_expires'],
    //         access_token: cookies['access_token'],
    //         access_token_expires: cookies['access_token_expires'],
    //     }

    //     //get dealers
    //     var myHeaders = new Headers();
    //     myHeaders.append("Accept", "application/json");
    //     myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
    //     myHeaders.append("Content-Type", "application/json");
    
    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         redirect: 'follow',
    //         body: JSON.stringify({})
    //       }

    //     let tempDealerList = []
    //     let tempAdminList = []
    //     let tempContractList = []
    //     let tempSubagentList = []

    //     props.tokenSafeAPIRequest('/dealership/search?limit=99999', requestOptions, tokenValues, (result) => {
    //         console.log(result)
    //         result.map((dealer, index) => {
    //             tempDealerList.push({value: dealer.id, label: dealer.name})
    //         })
    //     })

    //     props.tokenSafeAPIRequest('/admin/search?limit=99999', requestOptions, tokenValues, (result) => {
    //         console.log(result)
    //         result.map((admin, index) => {
    //             tempAdminList.push({value: admin.id, label: admin.name})
    //         })
    //     })
        
    //     requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         redirect: 'follow',
    //         body: JSON.stringify({subagent: true})
    //       }

    //     props.tokenSafeAPIRequest('/user/search?limit=99999', requestOptions, tokenValues, (result) => {
    //         console.log(result)
    //         tempSubagentList.push({value: '', label: 'All Subagents'})
    //         result.map((subagent, index) => {
    //             tempSubagentList.push({value: subagent.id, label: subagent.name})
    //         })
    //         setAllAgents(tempSubagentList)
    //     })

    //     requestOptions = {
    //         method: 'GET',
    //         headers: myHeaders,
    //         redirect: 'follow',
    //       }

    //     props.tokenSafeAPIRequest('/admin/contract_types', requestOptions, tokenValues, (result) => {
    //         console.log(result)
    //         result.VSC_contracts.map((type, index) => {
    //             tempContractList.push({value: 'VSC - ' + type, label: 'VSC - ' + type})
    //         })
    //         result.ancillary_contracts.map((type, index) => {
    //             tempContractList.push({value: 'Ancillary - ' + type, label: 'Ancillary - ' + type})
    //         })
    //     })

    //     setFilterData([
    //         {
    //             title: 'Admin',
    //             data: tempAdminList,
    //             value: {label: '', value: ''},
    //             key: 'admin_ID',
    //         },
    //         {
    //             title: 'Dealership',
    //             data: tempDealerList,
    //             value: {label: '', value: ''},
    //             key: 'dealership_ID',
    //         },
    //         {
    //             title: 'Report Type',
    //             data: [
    //                 {value: 'Saturday', label: 'Saturday'},
    //                 {value: 'Adjustment', label: 'Adjustment'},
    //                 {value: 'Weekly 7 Reversal', label: 'Weekly 7 Reversal'},
    //                 {value: 'Void', label: 'Void'},
    //                 {value: 'Rejection', label: 'Rejection'},
    //                 {value: 'Unwind', label: 'Unwind'},
    //                 {value: 'Cancellation', label: 'Cancellation'},
    //                 {value: 'Over / Under', label: 'Over / Under'},
    //                 {value: 'Payback', label: 'Payback'},
    //                 {value: 'Claim', label: 'Claim'},
    //                 {value: '100% Cancellation', label: '100% Cancellation'},
    //             ],
    //             value: {label: '', value: ''},
    //             key: 'report_type',
    //         },
    //         {
    //             title: 'Contract Type',
    //             data: tempContractList,
    //             value: {label: '', value: ''},
    //             key: 'contract_type',
    //         },
    //         {
    //             title: 'Date',
    //             data: [
    //                 {value: 7, label: '1 Week'},
    //                 {value: 30, label: '1 Month'},
    //                 {value: 182, label: '6 Months'},
    //                 {value: 365, label: '1 Year'},
    //             ],
    //             value: {label: '', value: ''},
    //             key: 'date',
    //         },
    //     ])
    // },[])

    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        let filterDataBy = {}
        let startDate = ''
        filterData.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result      
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...filterDataBy
          })
        }

        let tempStringAdjust = ''
        if (selectedAgent.value === '')
        {
            tempStringAdjust = '';
        }
        else
        {
            tempStringAdjust = '/' + selectedAgent.value
        }

        props.tokenSafeAPIRequest('/metrics/combined_subagent_report' + tempStringAdjust + '?limit=15&fetch_links=true&sort_field=' + sort, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (result.reports.length === 0)
            {
                setTableValues([
                    [tableTitles[0].default], 
                    [tableTitles[1].default],
                    [tableTitles[2].default],
                    [tableTitles[3].default], 
                    [tableTitles[4].default],
                    [tableTitles[5].default],
                    [tableTitles[6].default], 
                    [tableTitles[7].default], 
                    [tableTitles[8].default], 
                    [],
                    [],
                    [],
                ])
                let tempStats = [
                    {title: 'Total Gross', value: '$' + 0},
                    {title: 'Total Net', value: '$' + 0},
                    //{title: 'Avg. Commission Rate', value: 0 + '%'},
                    //{title: 'Total Contract Count', value: 0},
                    {title: 'Active Dealers', value: 0},
                ]
                setHeaderValues(tempStats)
            }
            else
            {
                let tempStats = [
                    {title: 'Total Gross', value: '$' + formatNumber(result.statistics.total_gross)},
                    {title: 'Total Net', value: '$' + formatNumber(result.statistics.total_net)},
                    // {title: 'Avg. Commission Rate', value: (Number.parseFloat(result.statistics.avg_commission_rate) * 100).toFixed(2) + '%'},
                    // {title: 'Total Contract Count', value: Number.parseFloat(result.statistics.total_contract_count).toFixed(2)},
                    {title: 'Active Dealers', value: formatNumber(result.statistics.total_dealerships)},
                ]
                setHeaderValues(tempStats)
    
                let tempTableValues = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                ]
                let tempReportID = []
                result.reports.map((report, index) => {
                    tempTableValues[0].push(report.quantity)
                    tempTableValues[1].push(report.subagent_owner_ID === null ? report.subagent_ID.first_name + ' ' + report.subagent_ID.last_name:report.subagent_owner_ID.first_name + ' ' + report.subagent_owner_ID.last_name)
                    tempTableValues[2].push(report.admin_ID === null ? 'No Admin':report.admin_ID.name)
                    tempTableValues[3].push(report.dealership_ID.name)
                    tempTableValues[4].push(report.report_type)
                    tempTableValues[5].push(report.contract_type + " - " + report.contract_subtype)
                    tempTableValues[6].push(formatDate(new Date(report.date)))
                    tempTableValues[7].push(report.contract_gross)
                    tempTableValues[8].push(report.total_spiffee_payout)
                    tempTableValues[9].push(report.dppp_amount)
                    tempTableValues[10].push(report.gap_amount)
                    tempTableValues[11].push(report.inside_sales_payout)
                    tempReportID.push([report.id, report.code])
                })

                console.log(tempTableValues)

                setTableValues(tempTableValues)
            }
        })
        setSkip(0)
    },[selectedAgent, filterData, sort])

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const filterValues = ['Liberty Shield','VSC','Weekly 7','4 Season Motors']

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Quantity',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: 0,
        },
        {
            title: 'Subagent',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Admin',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Dealer',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Report Type',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Contract Type',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Date',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Net',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${props.data}</span>),
            default: 0,
        },
        {
            title: 'Spiffs',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${Math.round(props.data * 100) / 100}</span>),
            default: 0,
        },
        {
            title: 'DPPP',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${Math.round(props.data * 100) / 100}</span>),
            default: 0,
        },
        {
            title: 'GAP Plus',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${Math.round(props.data * 100) / 100}</span>),
            default: 0,
        },
        {
            title: 'Inside Sales',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${Math.round(props.data * 100) / 100}</span>),
            default: 0,
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default], 
        [tableTitles[4].default],
        [tableTitles[5].default],
        [tableTitles[6].default], 
        [tableTitles[7].default], 
        [tableTitles[8].default], 
        [tableTitles[9].default], 
        [tableTitles[10].default], 
        [tableTitles[11].default], 
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e,x,y) => {
        console.log(e,x,y)
    }

    const updateSelectedAgent = (agent) => {
        console.log(agent)
        setSelectedAgent(agent)
        //updateStatisticsAndTable(agent)
    }

    const countDuplicateSubarrays = (arrayOfArrays) => {
        const arrayMap = new Map();
      
        arrayOfArrays.forEach(subArray => {
          // Convert each subarray to a string to use as a unique key for comparison
          const key = JSON.stringify(subArray);
          if (arrayMap.has(key)) {
            arrayMap.set(key, arrayMap.get(key) + 1);
          } else {
            arrayMap.set(key, 1);
          }
        });
      
        // Convert the map back into an array of arrays with quantities
        return Array.from(arrayMap, ([key, value]) => [JSON.parse(key), value]);
    }

    function formatDate(date) {
        if (!(date instanceof Date)) {
            throw new Error('Input must be a Date object');
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString().substr(-2); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${month}/${day}/${year}`;
    }

    const processData = () => {
        console.log('hi')
    }

    const onBottom = () => {

        console.log(skip)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        let filterDataBy = {}
        let startDate = ''
        filterData.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result      
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            subagent_ID: selectedAgent.value === '' ? null:selectedAgent.value,
            ...filterDataBy
          })
        }

        let tempStringAdjust = ''
        if (selectedAgent.value === '')
        {
            tempStringAdjust = '';
        }
        else
        {
            tempStringAdjust = '/' + selectedAgent.value
        }

        props.tokenSafeAPIRequest('/metrics/combined_subagent_report' + tempStringAdjust + '?limit=15&fetch_links=true&skip=' + (skip+15) + '&sort_field=' + sort, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (result.reports.length === 0)
            {
                return '';
            }
            else
            {
                let tempTableValues = tableValues
                let tempReportID = []
                result.reports.map((report, index) => {
                    tempTableValues[0].push(report.quantity)
                    tempTableValues[1].push(report.subagent_owner_ID === null ? report.subagent_ID.first_name + ' ' + report.subagent_ID.last_name:report.subagent_owner_ID.first_name + ' ' + report.subagent_owner_ID.last_name)
                    tempTableValues[2].push(report.admin_ID === null ? 'No Admin':report.admin_ID.name)
                    tempTableValues[3].push(report.dealership_ID.name)
                    tempTableValues[4].push(report.report_type)
                    tempTableValues[5].push(report.contract_type + " - " + report.contract_subtype)
                    tempTableValues[6].push(formatDate(new Date(report.date)))
                    tempTableValues[7].push(report.contract_gross)
                    tempTableValues[8].push(report.total_spiffee_payout)
                    tempTableValues[9].push(report.dppp_amount)
                    tempTableValues[10].push(report.gap_amount)
                    tempTableValues[11].push(report.inside_sales_payout)
                    tempReportID.push([report.id, report.code])
                })

                console.log(tempTableValues)

                setTableValues(tempTableValues)
            }
        })
        setSkip((skip) => skip + 15)
    }



    const saveFilters = (data, displayData) => {
        setFilterData((prevState, index) => {
            return prevState.map((item, index) => { console.log(item)
                return {...item, value: {value: data[item.key], label: displayData[item.key]}}
            })
        })
    } 

    const removeFilter = (key) => {
        setFilterData((prevState, index) => {
            return prevState.map((item, index) => {
                if (item.key === key)
                {
                    return {...item, value: {value: '', label: ''}}
                }
                else {return item}
            })
        })

        setFilterModalDisplayData((prevState) => {
            return {...prevState, [key]: ''}
        })

        setFilterModalData((prevState) => {
            return {...prevState, [key]: ''}
        })
    }

    const [filterModalDisplayData, setFilterModalDisplayData] = useState({
        admin_ID: '',
        dealership_ID: '',
        report_type: '',
        contract_type: '',
        date: '',
    })

    const [filterModalData, setFilterModalData] = useState({
        admin_ID: '',
        dealership_ID: '',
        report_type: '',
        contract_type: '',
        date: '',
    })

    const updateFilterModalData = (e, key) => {
        setFilterModalDisplayData(data => ({
            ...data,
            [key]: e.label
        }))
        setFilterModalData(data => ({
            ...data,
            [key]: e.value
        }))
    }

    const sortOptions = [
        { value: 'timestamp', label: 'Date' },
        { value: 'contract_gross', label: 'Gross' },
        { value: 'net', label: 'Net' },
    ]

    const sortToName = {
        timestamp: 'Date',
        contract_gross: 'Gross',
        net: 'Net',
    }

    const masterList = [
        {
            name: 'Export Report',
            link: '',
            onClick: () => {setExportDataModal(true)},
        },
        {
            name: 'Combined Dealer Report',
            link: '/output/combinedDealerReport',
            key: "CombinedDealerReport",
            onClick: () => {},
        },
        {
            name: 'Combined Subagent Report',
            link: '/output/combinedSubagentReport',
            key: "CombinedSubagentReport",
            onClick: () => {},
        },
        {
            name: 'Contract Count Report',
            link: '/output/contractCountReport',
            key: "ContractCountReport",
            onClick: () => {},
        },
        {
            name: 'Inside Sales Report',
            link: '/output/insidesalesreport',
            key: "InsideSalesReport",
            onClick: () => {},
        },
        // {
        //     name: '-List of Gift Cards',
        //     link: '/output/listOfGiftCards',
        //     key: "GiftCardList",
        //     onClick: () => {},
        // },
        {
            name: 'List of Reserve Input/Outputs',
            link: '/output/listOfReserveInputs',
            key: "ReserveAccounts",
            onClick: () => {},
        },
        // {
        //     name: '-List of Reserve Outputs',
        //     link: '/output/listOfReserveOutputs',
        //     key: "ReserveInputs",
        //     onClick: () => {},
        // },
        {
            name: 'List of Saturday Reports',
            link: '/output/listOfSaturdayReports',
            key: "SaturdayReport",
            onClick: () => {},
        },
        {
            name: 'New Dealer Signups',
            link: '/output/newDealerSignups',
            key: "NewDealerSignups",
            onClick: () => {},
        },
        // {
        //     name: '-Outstanding Pre-spiffs',
        //     link: '/output/outstandingPrespiffs',
        //     key: "OutstandingPrespiffs",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Recent Address Changes',
        //     link: '/output/recentAddressChanges',
        //     key: "RecentAddressChanges",
        //     onClick: () => {},
        // },
        {
            name: 'Reporting History',
            link: '/output/reportingHistory',
            key: "ReportingHistory",
            onClick: () => {},
        },
        // {
        //     name: '-Reserve Account Reports',
        //     link: '/output/reserveAccountReports',
        //     key: "ReserveAccountReport",
        //     onClick: () => {},
        // },
        {
            name: 'Spiffees Report',
            link: '/output/spiffeesReport',
            key: "SpiffeesReport",
            onClick: () => {},
        },
        // {
        //     name: '-Subagent Business',
        //     link: '/output/subagentBusiness',
        //     key: "SubagentBusiness",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Subagent Net & Inside Sales',
        //     link: '/output/subagentNetAndInsideSales',
        //     key: "SubagentNetInsideSales",
        //     onClick: () => {},
        // },
        {
            name: 'Void & Needs to be Replaced',
            link: '/output/voidAndNeedsReplaced',
            key: "VoidAndNeedsToBeReplaced",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Weeks List',
            link: '/output/reserveAccountWeeks',
            key: "ReserveAccountWeeks",
            onClick: () => {},
        },
        {
            name: 'Combined Spiffees Report',
            link: '/output/combinedSpiffeesReport',
            key: "CombinedSpiffeesReport",
            onClick: () => {},
        },
        {
            name: 'List of Expense Reports',
            link: '/output/expenseReports',
            key: "ListOfExpenseReports",
            onClick: () => {},
        },
        {
            name: 'List of Adjustment Reports',
            link: '/output/adjustmentReports',
            key: "ListOfAdjustmentReports",
            onClick: () => {},
        },
        {
            name: 'List of DPPP/GAP Plus',
            link: '/output/DPPPGAP',
            key: "ListOfDPPP/GAP",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Totals',
            link: '/output/reserveAccountTotals',
            key: "ReserveAccountTotals",
            onClick: () => {},
        },
        {
            name: 'Prespiff Paid Off',
            link: '/output/prespiffPaidOff',
            key: "PrespiffPaidOff",
            onClick: () => {},
        },
        {
            name: 'Dealer Totals',
            link: '/output/dealerTotals',
            key: "DealerTotals",
            onClick: () => {},
        },
    ]

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/permission/me', requestOptions, '', (result, status) => {
            console.log(result) //'*':'*' - Superadmin
            let tempButtonList = [{
                name: 'Export Report',
                link: '',
                onClick: () => {setExportDataModal(true)},
            }]
            if (result.static_role === 'superadmin' || result.static_role === 'admin')
            {
                localStorage.setItem('button_list', JSON.stringify(masterList))
                setButtonList(masterList)
            }
            else
            {
                result.allow.Metrics.map((metric) => {
                    masterList.map((button) => {
                        if (metric === button.key)
                        {
                            tempButtonList.push(button)
                        }
                    })
                })
                tempButtonList = tempButtonList.sort((a,b) => {
                    if (a.name === "Export Report") return -1; // Move "Export Report" to the top
                    if (b.name === "Export Report") return 1;
                    return a.name.localeCompare(b.name);
                })
                localStorage.setItem('button_list', JSON.stringify(tempButtonList))
                setButtonList(tempButtonList)
            }
        })  
    },[])

    const [exportDataModal, setExportDataModal] = useState(false)
    const [buttonList, setButtonList] = useState(JSON.parse(localStorage.getItem('button_list')))

    return (
        <OutputLayoutColumn colors={props.colors}>
            <Sidebar colors={props.colors} tab={1}></Sidebar>
            <Controller colors={props.colors} tokenSafeAPIRequest={props.tokenSafeAPIRequest} activeButton={true} buttonList={buttonList} title={"Outputs"}></Controller>
            <div></div>{/* <OutputColumn colors={props.colors} list={list} title={"Subagents"}></OutputColumn> */}
            <div> {/*THERE IS A HIDDEN STYLE TAG FROM THE OUTPUT LAYOUT --- width: "auto", marginLeft: '20px', display: 'flex', flexDirection: 'column'*/}
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Dropdown colors={props.colors} onChange={(e) => updateSelectedAgent(e)} index={props.index} indexParent={props.indexParent} data={allAgents} width={195} value={selectedAgent.label}>Test</Dropdown>
                    <span onClick={() => console.log(filterData)} style={{fontWeight: '600', marginLeft: '20px', marginTop: '6px'}}>Filter Subagent</span>
                </div>
                <OutputHeader colors={props.colors} headerValues={headerValues}></OutputHeader>
                <OutputFilter colors={props.colors} onClick={() => setFiltersModal(true)} filterData={filterData} removeFilter={removeFilter} setSort={setSort} sort={sort} sortOptions={sortOptions} sortToName={sortToName}></OutputFilter>
                <OutputBarChart colors={props.colors} title={'Net'}></OutputBarChart>
                <OutputTable colors={props.colors} tableData={tableValues} tableInfo={tableTitles} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false} onBottom={onBottom}></OutputTable>
            </div>
            <ModalManager colors={props.colors}>
                <FiltersModal colors={props.colors} isOpen={filtersModal} setIsOpen={() => setFiltersModal(false)} filterData={filterData} saveFilters={saveFilters} displayData={filterModalDisplayData} data={filterModalData} updateData={updateFilterModalData}></FiltersModal>
                <ExportData colors={props.colors} isOpen={exportDataModal} setIsOpen={() => setExportDataModal(false)}></ExportData>
            </ModalManager>
        </OutputLayoutColumn>
    )
}

export default Main
